import React from "react";
import { Link } from "react-router-dom";

import bnrImg1 from "../../images/banner/img1.jpg";
// import pic1 from "../../images/about/pic-1.jpg";
// import icon1 from "../../images/icon/doc.png";
// import icon2 from "../../images/icon/icon2.png";
// import icon3 from "../../images/icon/icon3.png";
// import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
// import animateRotate from "../../images/shap/plus-blue.png";
import onBoarding1 from "../../images/banner/on-board1.png";

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";
import TeethRight from "../../images/shap/dr-logo.png";
import TeethLeft from "../../images/shap/dr-logo.png";

const OnBoarding = () => {
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner banner-lg contact-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Join Us</h1>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={TeethLeft} alt="" />
            <img className="pt-img2 animate2" src={animate2} alt="" />
            <img className="pt-img3 animate-wave" src={TeethRight} alt="" />
          </div>
        </div>

        <div className="banner-img text-center my-3">
          <h3>Complete Your Profile Setup</h3>
          <img src={onBoarding1} alt="" />

          <div className="my-30">
            <Link to="/on-boarding/join-now" className="btn btn-primary">
              Get Started
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OnBoarding;
