import React from "react";
import bnrImg1 from "../../images/banner/img1.jpg";
// import pic1 from "../../images/about/pic-1.jpg";
// import icon1 from "../../images/icon/doc.png";
// import icon2 from "../../images/icon/icon2.png";
// import icon3 from "../../images/icon/icon3.png";
// import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
// import animateRotate from "../../images/shap/plus-blue.png";
import TeethRight from "../../images/shap/dr-logo.png";
import TeethLeft from "../../images/shap/dr-logo.png";
const JoinSection = ({ title }) => {
  return (
    <div className="banner-wraper">
      <div
        className="page-banner banner-lg contact-banner"
        style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
      >
        <div className="container">
          <div className="page-banner-entry text-center">
            <h1>{title}</h1>
          </div>
        </div>
        {/* <div className="bg-widgets">
        <img src={TeethLeft} alt="TeethLeft" className="medical" />
        <img src={TeethRight} alt="TeethRight" className="medical" />
      </div> */}
        <img className="pt-img1 animate-wave" src={TeethRight} alt="" />
        <img className="pt-img2 animate2" src={animate2} alt="" />
        <img className="pt-img3 animate-wave" src={TeethLeft} alt="" />
      </div>
    </div>
  );
};

JoinSection.defaultProps = {
  title: "Join Us",
};
export default JoinSection;
