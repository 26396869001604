import React, { useEffect } from "react";

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import circleDots from "../../images/shap/circle-dots.png";
import "../../css/resposive.css";
import TeethRight from "../../images/shap/dr-logo.png";
import TeethLeft from "../../images/shap/dr-logo.png";

const Terms = () => {
  useEffect(() => {
    setTimeout(() => {
      console.clear();
    }, 1000);
  }, []);

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Terms &amp; Conditions</h1>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={TeethLeft} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-wave" src={TeethRight} alt="" />
          </div>
        </div>
        {/* <section className="section-sp1 about-area"> */}
        <div className="container shadow-sm p-5 rounded custom-res">
          <h1>Terms of Use</h1>
          <h6>Last Update July 28, 2022 </h6>
          <p>
            Practice Wise Inc. and/or its affiliates (“us”, “we”, “our”, or “The
            Company”) operates this website and the Practice Wise mobile
            application (the “Service” or “The Website”).
          </p>
          <br />
          <p>
            By using or accessing this site, or a subdomain of any such, any
            mobile application for such websites or any other website operated
            by us on which these Terms and Conditions are posted via a link or
            otherwise (each referred to herein as a “Site”), you acknowledge
            that you agree to and are subject to the following terms and
            conditions, as well as our Privacy Policy (collectively, the
            "Terms"). If you do not fully agree to these Terms, Privacy Policy
            and any other terms and conditions posted or linked to any Site, you
            are not authorized to access or otherwise use the Site. Under these
            Terms, "use" or "access" of the Site specifically includes any
            direct or indirect access or use of the Site or any cached version
            of the Site and any direct or indirect access or use of any
            information or content on the Site, regardless of how obtained and
            the term "Site" includes, without limitation, any cached version
            thereof.
          </p>
          <br />
          <p>
            You should read through all the Terms carefully. The Terms
            constitute a legally binding agreement between you and The Company.
            You are not authorized to use this Site unless you are at least 18
            and able to enter into legally binding contracts. We do not
            knowingly collect the information of anyone under the age of 18.{" "}
          </p>
          <br />
          <p>
            If you arrived on the Site after having been re-directed or
            otherwise clicking on another website, you agree that these Terms
            shall govern your use of this Site.
          </p>
          <br />
          <div
            className="p-5 px-7 rounded mb-5 mt-1"
            style={{ background: "#F0F4FF", color: "#5F73E8" }}
          >
            <ol type="1">
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#1">Data Transmittal</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#2">Identity Verification.</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#3">Limitations on Communications </a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#4">Social Media </a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#5">Notification of Infringement</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#6">Unsolicited Ideas and Feedback</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#7">Links to Third Party Sites.</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#8">Limitation of Liability</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#9">Disclaimers.</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#10">Release</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#11">Jurisdiction</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#12">General</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#13">Content</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#14">Uses of Our Trademarks or Logos</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#15">Hypertext Links. </a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#16">Unauthorized Payment Methods</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#17">Subscription Term</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#18">Registration and Account Security</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#19">Community Payments Terms</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#20">Consent to receive electronic Communications</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#21">
                  Use of Third Party contracts notices and other documentation
                  and E-signature{" "}
                </a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#22">Credit, Screenings and Background Checks</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#23">User Accounts</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#24">User Content </a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#25">User Conduct </a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#26">Paid Promotions</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#27">Credit Checks and Background Reporting</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#28">Dwolla's Term of Services</a>
              </li>
              <li style={{ listStyleType: "disc" }} className="my-1">
                <a href="#29">Identity Verification</a>
              </li>
            </ol>
          </div>
          <h6 id="1">
            1. Your E-mail Address and Data; Our Privacy Policy; Data
            Transmittal.
          </h6>
          <p>
            When you provide your e-mail address, name or other information to
            us in connection with your use or access to the Site, any service or
            tool provided on the Site or otherwise, you agree to allow the Site
            and its affiliated websites to add your e-mail address, name or
            other information provided to our database of users. You may receive
            one or more promotional e-mails from either the Site or a website of
            one of The Company’s affiliates. You are welcome to opt not to
            receive such promotional e-mails from the Site or such affiliates’
            websites at any time. Please review our Privacy Policy for more
            information regarding our email and other data collection practices
            and safeguards, and how to opt not to receive such emails. Your use
            of the Site signifies your acknowledgment of, and agreement, with
            our Privacy Policy.
            <br />
            <br />
            Each user acknowledges and agrees that, regardless of such user’s
            physical location, we may store and process any data transmitted to
            the Site from such user at locations both within and outside of the
            United States.
            <br />
            <br />
            In the event that you use any of our tools that we may from time to
            time offer that integrates in any way with a third party website to
            which you have provided data or information, you acknowledge and
            agree that such third party website shall be responsible for how the
            data or information you have provided to such website is handled.
          </p>
          <br />
          <h6 id="2">2. Identity Verification.</h6>
          <p>
            User verification on the Internet is difficult and we cannot, and do
            not assume any responsibility for, the confirmation of each user's
            purported identity.
            <br />
            <br />
            You agree to (i) keep your password and online ID for both your
            account with us and your email account secure and strictly
            confidential, providing it only to authorized users of your
            accounts, (ii) instruct each person to whom you give your online ID
            and password that he or she is not to disclose it to any
            unauthorized person, (iii) notify us immediately and select a new
            online ID and password if you believe your password for either your
            account with us or your email account may have become known to an
            unauthorized person, and (iv) notify us immediately if you are
            contacted by anyone requesting your online ID and password. Further,
            if we suspect any unauthorized access to your account, upon our
            request, you agree to promptly change your ID and password and take
            any other related activities as we may reasonably request.
            <br />
            <br />
            We discourage you from giving anyone access to your online ID and
            password for your account with us and your email account. However,
            if you do give someone your online ID and online password, or if you
            fail to adequately safeguard such information, you are responsible
            for any and all transactions that the person performs while using
            your account with us or your email account, even those transactions
            that are fraudulent or that you did not intend or want to be
            performed.
            <br />
            <br />
            EACH USER ACKNOWLEDGES AND AGREES THAT: (1) NEITHER THE COMPANY NOR
            ANY OF ITS AFFILIATES WILL HAVE ANY LIABILITY TO ANY USER FOR ANY
            UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ID OR PASSWORD; AND
            (2) THE UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD FOR YOUR
            ACCOUNT OR YOUR EMAIL ACCOUNT COULD CAUSE YOU TO INCUR LIABILITY TO
            BOTH THE COMPANY AND OTHER USERS. Further, we may, without notice to
            you, suspend or cancel your subscription at any time even without
            receiving notice from you if we suspect, in our sole discretion,
            that your account with us or your email account is being used in an
            unauthorized or fraudulent manner
          </p>
          <br />
          <h6 id="3">
            3. Limitations on Communications and Use of Other Users’
            Information; No Spam.{" "}
          </h6>
          <p>
            You agree that, with respect to other users' personal information
            that you obtain directly or indirectly from or through the Site or
            through any Site-related communication, transaction or software, we
            have granted to you a license to use such information only for: (a)
            Site-related communications that are not unsolicited commercial
            messages, (b) using services offered through the Site, and (c)
            inquiring about or otherwise facilitating a financial transaction
            between you and the other user related to the purpose of the Site
            (such as inquiring about or Leasing an online Leasing or charging a
            personal credit card). Any other purpose will require express
            permission from the user. You may not use any such information for
            any unlawful purpose or with any unlawful intent.
            <br />
            <br />
            In all cases, you must give users an opportunity to remove their
            information from your address book or database or other records and
            a chance to review what information you have collected about them.
            In addition, under no circumstances, except as defined in this
            provision, may you disclose personal information about another user
            to any third party without both our consent and the consent of the
            other user. You agree that other users may use your personal
            information to communicate with you in accordance with this
            provision. Further, you agree that you will protect other users’
            personal information with the same degree of care that you protect
            your own confidential information (using at minimum a reasonable
            standard of care), and you assume all liability for the misuse,
            loss, or unauthorized transfer of such information.
            <br />
            <br />
            Messages within The Website are protected for private use but
            available to The Company limited staff for reviewing in the event
            the user is suspected of being fraudulent or participating in
            fraudulent activities.
          </p>
          <br />
          <h6 id="4">4. Social Media or Third Party Websites.</h6>
          <p>
            If the Site offers a tool or service which allows us to access or
            use any profile or other information about you that you have
            provided to Facebook or another third party website (each a “Social
            Media Site”) and you decide to use such a tool or service, you
            acknowledge and agree that:
            <br />
            <br />
            (i) The information or content that are a part of your Social Media
            Site profile, which you have designated as “public” (or a similar
            designation) (with such information or content and referred to
            herein as “Social Media Content”) may be accessed and used by us in
            connection with the Site;
            <br />
            <br />
            (ii) The Social Media Content will be considered “user-generated
            content” under these Terms and both you and we shall have the same
            rights and responsibilities as you and we have with respect to
            user-generated content under these Terms;
            <br />
            <br />
            (iii) In the event that the Social Media Content was for any reason
            misclassified with a public or similar designation or is otherwise
            inaccurate or to which you do not agree with for any reason, you
            agree to work with the Social Media Site to make any changes or
            resolve any disputes and acknowledge that we will not be able to
            provide you with recourse; and
            <br />
            <br />
            (iv) The operation of your profile and account with and on the
            Social Media Site shall continue to be governed by the terms and
            conditions and privacy policy of such Social Media Site.
          </p>
          <br />
          <h6 id="5">5. Notification of Infringement; DMCA Policy.</h6>
          <p>
            We respect the intellectual property rights of others, and The
            Company does not permit, condone, or tolerate the posting of any
            content on the Site that infringes any person's copyright. The
            Company will terminate, in appropriate circumstances, a member who
            is the source of repeat infringements of copyright. Should you
            become aware of or suspect any copyright infringement on this Site,
            please refer to our procedures for Notification of Copyright
            Infringement.
          </p>
          <br />
          <h6 id="6">6. Unsolicited Ideas and Feedback.</h6>
          <p>
            Unsolicited Ideas: From time to time, users submit to us ideas or
            suggestions pertaining to our business, such as ideas for new or
            improved products or technologies, website or tool enhancements,
            processes, materials, marketing plans or new product names. We are
            under no obligation to review or consider them. If you choose to
            submit any ideas, original creative artwork, suggestions or other
            works (“Feedback”) in any form to us, then regardless of what you
            say, write or provide to us in connection with your submissions, the
            following terms shall apply.
            <br />
            <br />
            The sole purpose of this policy is to avoid potential
            misunderstandings or disputes in the event that any part of our
            business, such as our products, websites, technologies or marketing
            strategies, seem similar to any of your submissions. If you provide
            any submissions to us, you agree that: (1) your submission and its
            contents will automatically become the property of The Company,
            without any compensation to you; (2) The Company may use or
            redistribute any such submission and its contents for any purpose
            and in any way; (3) there is no obligation for The Company to review
            any submission; and (4) there is no obligation to keep any
            submission confidential.
            <br />
            <br />
            Feedback on our Business: We welcome your feedback regarding many
            areas of our business. If you want to send us your feedback, we
            simply request that you send it to us using the links under “General
            – Contact Us” below or you can choose from the many other listed
            areas for your feedback. Please provide only specific feedback on
            our websites and services. Keep in mind that we assume no obligation
            to keep any feedback you provide confidentially and we reserve the
            right to use or disclose such information in any manner.
            <br />
            <br />
          </p>
          <br />
          <h6 id="7">7. Links to Third Party Sites.</h6>
          <p>
            This Site may contain links and pointers to other Internet sites,
            resources, and sponsors of the Site. Links to and from the Site to
            other third-party sites, maintained by third parties, do not
            constitute an endorsement by us of any third parties, the
            third-party sites or the contents thereof. We may also provide tools
            to allow interaction between the Site and a third party site, such
            as a Social Media Site. We are not responsible in any way for such
            third-party sites or resources and your use of such sites and
            resources will not be governed by these Terms.
            <br />
            <br />
            Transactions by third party vendors are final and with no refunds.
            Any disputes should be taken up with the third party. Third party
            vendors include, but are not limited to: PayPal, Stripe, and any and
            all of their affiliates. All sales of third-party products are
            final.
          </p>
          <br />
          <h6 id="8">8. Limitation of Liability. </h6>
          IN NO EVENT WILL THE COMPANY, SUBSIDIARIES, AFFILIATES, OFFICERS,
          DIRECTORS, CONSULTANTS, AGENTS AND/OR EMPLOYEES, OR ANY THIRD PARTY
          PROVIDER OF A SERVICE OR TOOL OFFERED ON ANY SITE OF A THE COMPANY’s
          (EACH A “THIRD PARTY PROVIDER”), BE LIABLE FOR ANY LOST PROFITS OR ANY
          INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES
          ARISING OUT OF, BASED ON, OR RESULTING FROM (A) OUR SITE, (B) THESE
          TERMS, (C) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (D) USE
          OF THE SITE, TOOLS OR SERVICES WE PROVIDE, OR ANY THIRD PARTY PROVIDER
          PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE SITE, BY YOU OR
          ANY THIRD PARTY (E) ANY USER CONTRIBUTED CONTENT, (F) INTERACTION
          BETWEEN OUR SITE AND ANY THIRD PARTY SITE, INCLUDING WITHOUT
          LIMITATION A SOCIAL MEDIA SITE, FACILITATED BY A TOOL OR SERVICE ON
          OUR SITE AND/OR (G) ANY ACTUAL OR ATTEMPTED COMMUNICATION OR
          TRANSACTION, INCLUDING WITHOUT LIMITATION, ANY PAYMENT TRANSACTION
          (EVEN IF WE OR ANY THIRD PARTY PROVIDER RECEIVE A COMMISSION OR FEE IN
          CONNECTION THEREWITH) BETWEEN USERS, IN EACH CASE, EVEN IF WE HAVE
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS AND
          EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1)
          BREACH OF CONTRACT, (2) BREACH OF WARRANTY, (3) STRICT LIABILITY, (4)
          TORT, (5) NEGLIGENCE, OR (6) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM
          EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE
          LAW.
          <br />
          <br />
          IF YOU ARE DISSATISFIED WITH THE SITE, YOU DO NOT AGREE WITH ANY PART
          OF THE TERMS, OR HAVE ANY OTHER DISPUTE OR CLAIM WITH OR AGAINST US,
          ANY THIRD PARTY PROVIDER OR ANY USER OF THE SITE WITH RESPECT TO THESE
          TERMS OR THE SITE, THEN YOUR SOLE AND EXCLUSIVE REMEDY AGAINST US IS
          TO DISCONTINUE USING THE SITE. IN ALL EVENTS, OUR LIABILITY, AND THE
          LIABILITY OF THE COMPANY, TO YOU OR ANY THIRD PARTY IN ANY
          CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS LIMITED
          TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO US IN THE TWELVE
          MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY OR (B) $100.00 IN
          THE AGGREGATE FOR ALL CLAIMS.
          <p></p>
          <br />
          <h6 id="9">9. Disclaimers.</h6>
          <p>
            THE SITE, INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, MATERIALS, AND
            INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS
            PROVIDED "AS IS." TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE MAKE
            NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER FOR THE
            CONTENT ON THE SITE OR THE MATERIALS, INFORMATION AND FUNCTIONS MADE
            ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED THROUGH THE SITE, FOR
            ANY PRODUCTS OR SERVICES OR HYPERTEXT LINKS TO THIRD PARTIES OR FOR
            ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE
            INFORMATION THROUGH THE SITE OR ANY LINKED SITE, EVEN IF WE BECOME
            AWARE OF ANY SUCH BREACHES. FURTHER, WE EXPRESSLY DISCLAIM ANY
            EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION,
            NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
            OR ACCURACY. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE
            SITE OR ANY MATERIALS OR CONTENT CONTAINED THEREIN WILL BE
            UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
            THE SITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS.
            <br />
            <br />
            YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND FROM THIS
            SITE IS NOT CONFIDENTIAL AND YOUR COMMUNICATIONS OR USER CONTRIBUTED
            CONTENT MAY BE READ OR INTERCEPTED BY OTHERS. YOU FURTHER
            ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR USER
            CONTRIBUTED CONTENT TO US AND BY POSTING INFORMATION ON THE SITE, NO
            CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER RELATIONSHIP
            IS CREATED BETWEEN YOU AND US OTHER THAN PURSUANT TO THESE TERMS.
            <br />
            <br />
            YOU FURTHER ACKNOWLEDGE THAT BY DISPLAYING INFORMATION IN PARTICULAR
            DESTINATIONS, WE DO NOT REPRESENT OR WARRANT THAT TRAVEL TO SUCH
            DESTINATIONS IS WITHOUT RISK AND ARE NOT LIABLE FOR DAMAGES WITH
            RESPECT TO TRAVEL TO ANY DESTINATION.
          </p>
          <br />
          <h6 id="10">10. Release; Indemnification.</h6>
          <p>
            IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER USERS OF
            THE SITE (INCLUDING, WITHOUT LIMITATION, ANY DISPUTE BETWEEN USERS
            REGARDING ANY TRANSACTION OR USER CONTRIBUTED CONTENT) OR ANY THIRD
            PARTY PROVIDER OR ANY THIRD PARTY WEBSITE THAT MAY BE LINKED TO OR
            FROM OR OTHERWISE INTERACT WITH THE SITE, INCLUDING WITHOUT
            LIMITATION ANY SOCIAL MEDIA SITE, YOU HEREBY AGREE TO RELEASE,
            REMISE AND FOREVER DISCHARGE THE COMPANY, EACH OF THEIR RESPECTIVE
            AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED
            PERSONS OR ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS,
            COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES,
            OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE
            WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE
            FROM, RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE AND/OR YOUR USE
            OF THE SITE.
            <br />
            <br />
            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE
            SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO
            CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
            FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM
            MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
            <br />
            <br />
            YOU HEREBY AGREE TO INDEMNIFY, DEFEND AND HOLD THE COMPANY HARMLESS
            FROM AND AGAINST ANY AND ALL LIABILITY AND COSTS INCURRED BY THE
            INDEMNIFIED PARTIES IN CONNECTION WITH ANY CLAIM ARISING OUT OF YOUR
            USE OF THE SITE OR OTHERWISE RELATING TO THE BUSINESS WE CONDUCT ON
            THE SITE (INCLUDING, WITHOUT LIMITATION, ANY POTENTIAL OR ACTUAL
            COMMUNICATION, TRANSACTION OR DISPUTE BETWEEN YOU AND ANY OTHER USER
            OR THIRD PARTY), ANY CONTENT POSTED BY YOU OR ON YOUR BEHALF OR
            POSTED BY OTHER USERS OF YOUR ACCOUNT TO THE SITE, ANY USE OF ANY
            TOOL OR SERVICE PROVIDED BY A THIRD PARTY PROVIDER, ANY USE OF A
            TOOL OR SERVICE OFFERED BY US THAT INTERACTS WITH A THIRD PARTY
            WEBSITE, INCLUDING WITHOUT LIMITATION ANY SOCIAL MEDIA SITE OR ANY
            BREACH BY YOU OF THESE TERMS OR THE REPRESENTATIONS, WARRANTIES AND
            COVENANTS MADE BY YOU HEREIN, INCLUDING WITHOUT LIMITATION,
            ATTORNEYS' FEES AND COSTS. YOU SHALL COOPERATE AS FULLY AS
            REASONABLY REQUIRED IN THE DEFENSE OF ANY CLAIM.
            <br />
            <br />
            WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE
            DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO
            INDEMNIFICATION BY YOU AND YOU SHALL NOT, IN ANY EVENT, SETTLE ANY
            MATTER WITHOUT OUR WRITTEN CONSENT.
          </p>
          <br />
          <h6 id="11">
            11. Jurisdiction; Choice of Law and Forum; Time Limit.
          </h6>
          <p>
            THIS SITE IS OPERATED BY US IN THE UNITED STATES AND WE MAKE NO
            WARRANTY THAT THE MATERIALS AND CONTENT ON THE SITE ARE APPROPRIATE
            OR AVAILABLE FOR USE OUTSIDE OF THE UNITED STATES. THOSE WHO CHOOSE
            TO ACCESS THE SITE FROM OUTSIDE THE UNITED STATES DO SO ON THEIR OWN
            INITIATIVE AND ARE RESPONSIBLE FOR LOCAL LAWS, IF AND TO THE EXTENT
            THAT LOCAL LAWS ARE APPLICABLE.
            <br />
            <br />
            ANY AND ALL SERVICES AND RIGHTS OF USE HEREUNDER ARE PERFORMED,
            PERFORMABLE AND/OR SOLD IN THE STATE OF TEXAS, UNITED STATES OF
            AMERICA, AND YOU IRREVOCABLY AGREE AND CONSENT THAT ANY CAUSE OF
            ACTION YOU MAY SUBMIT IN CONNECTION WITH YOUR USE OF THE SITE OR
            PURSUANT TO THESE TERMS WILL BE FILED IN THE STATE OR FEDERAL COURTS
            IN TRAVIS COUNTY, TEXAS WHICH YOU ACKNOWLEDGE, CONSENT TO AND AGREE
            WILL BE THE EXCLUSIVE FORUM AND VENUE FOR ANY LEGAL DISPUTE BETWEEN
            YOU AND US. YOU ALSO AGREE THAT ANY DISPUTE BETWEEN YOU AND US WILL
            BE GOVERNED BY THE LAWS OF THE STATE OF TEXAS, WITHOUT REGARD TO
            CONFLICT OF LAWS PRINCIPLES.
            <br />
            <br />
            ANY CAUSE OF ACTION YOU MAY HAVE HEREUNDER OR WITH RESPECT TO YOUR
            USE OF THE SITE MUST BE COMMENCED BY FILING SUIT IN TRAVIS COUNTY,
            TEXAS, WITHIN ONE (1) YEAR AFTER THE INCIDENT UPON WHICH THE CLAIM
            OR CAUSE OF ACTION IS BASED FIRST OCCURRED.
          </p>
          <br />
          <h6 id="12">12. GENERAL.</h6>
          <p>
            Your agreement to abide by these Terms, the Privacy Policy and any
            other terms posted on any Site, with respect to any Site you use, is
            between you and the entity listed above operating such Site.
            <br />
            <br />
            No Agency: Our relationship is that of independent contractors, and
            no agency, partnership, joint venture, employee-employer or
            franchisor-franchisee relations is intended or created by these
            Terms or your use of the Site.
            <br />
            <br />
            Notices: Except as explicitly stated otherwise, any notices to us
            shall be given by postal mail to the reference in the Contact Us
            section.
            <br />
            <br />
            When we need to send you a notice, it will be sent to the email
            address you provide to the Site during the registration process or
            as later updated in your account (if applicable). The notice shall
            be deemed given upon receipt or 24 hours after an email is sent
            unless the sending party is notified that the email address is
            invalid. Alternatively, we may give you notice by certified mail,
            postage prepaid and return receipt requested, to any physical or
            electronic address provided to us during the registration process or
            as later updated in your account (if applicable). In such case,
            notice shall be deemed given three days after the date of mailing to
            a physical address and one day after mailing to an electronic
            address.
            <br />
            <br />
            Changes to the Site or these Terms and Conditions: We may change,
            suspend or discontinue any aspect of the Site at any time, including
            the availability of any Site features, database, or content. We may
            also impose limits on certain features or services or restrict your
            access to parts or the entire Site without notice or liability.
            <br />
            <br />
            This version of the Terms became effective on the date set forth
            above and this version amends the version effective prior to such
            date. We reserve the right, in our sole discretion, to amend these
            Terms, in whole or in part, at any time, with or without your
            consent and you acknowledge and agree that your consent to any such
            amendment is not required in the event the proposed amendment is
            clerical and/or non-substantive in nature. Notification of any
            amendment will be posted on the Site by the indication of the last
            amendment date at the top of these Terms and will be effective
            immediately. If you disagree with any non-clerical and/or
            substantive amendment to these Terms, then (i) your sole remedy as a
            member, or any other user other than a member, is to discontinue
            your use of the Site, and (ii) your sole remedy as a member is to
            withhold your consent to the applicability of the proposed amendment
            to your use of the Site, in which case your use of the Site will
            continue to be governed by the terms and conditions that were
            applicable to your use of the Site during the then current term of
            your subscription as the same were in effect immediately prior to
            the proposed amendment and you agree that you are responsible for
            keeping a copy of such terms. When members renew subscriptions, the
            terms in effect at the time of renewal will govern, provided that
            such terms may change as described above.
            <br />
            <br />
            We also reserve the right, in our sole discretion and from time to
            time, to offer programs, products or services with unique terms and
            conditions that are separate from and may supersede or supplement in
            certain respects these Terms. In such cases, your use of the Site
            with respect to such special program is governed by these Terms
            together with the terms and conditions of such program, product or
            service.
            <br />
            <br />
            We reserve the right, but assume no obligation, to agree to
            different or conflicting terms and conditions with respect to any
            user. Any such terms and conditions will not be enforceable unless
            specifically agreed to by us.
            <br />
            <br />
            The types of products and services (including the features, terms,
            and operation thereof) offered at the time of a member’s
            subscription or sign up for a non-subscription based are subject to
            the descriptions displayed at the time of use and/or purchase and
            are subject to change without notice or approval. We further reserve
            the right to offer additional products, services or features for
            purchase at any time. See also the section below relating to
            auto-renewal of subscriptions.
            <br />
            <br />
            Your Record of These Terms: We do not separately file the Terms
            entered into by each user of the Site. Please make a copy of these
            Terms for your records by printing and/or saving a downloaded copy
            of the Terms on your personal computer. Enforcement of These Terms:
            We may immediately terminate any user’s access to or use of the Site
            due to such user’s breach of these Terms or any other unauthorized
            use of the Site. However, we do not guarantee that we will take
            action against all breaches of these Terms. Our failure to take
            immediate action with respect to a breach by you or others does not
            waive our right to act with respect to such breach or any other
            breach. Any action or inaction by us in response to any breach of
            these Terms does not limit our rights with respect to actions we may
            take in response to any other similar or different type of breach.
            <br />
            <br />
            Entire Agreement, Conflict, Headings and Severability: These Terms
            constitute the entire agreement between us and you with respect to
            the matters set forth herein and supersede any prior agreement
            between us and you with respect to your use of the Site. Headings in
            these Terms are for reference only and do not limit the scope or
            extent of such section. In the event of any conflict between these
            Terms and any other terms and conditions applicable to a product,
            tool or service offered on our Site, the Terms herein shall prevail.
            If any portion of these Terms is found to be invalid or
            unenforceable by any court of competent jurisdiction, the other
            provisions of these Terms shall remain in full force and effect.
            Further, any provision of these Terms held invalid or unenforceable
            only in part or degree will remain in full force and effect to the
            extent not held invalid or unenforceable.
            <br />
            <br />
            Assignment: We may assign these Terms in our sole discretion. Users
            must obtain our prior written consent to assign these Terms, which
            may be granted or withheld by us in our sole discretion.
            <br />
            <br />
            Additional Terms and Conditions Applicable to Our Members In
            addition to being bound by the Terms set forth above, members who
            purchase subscriptions to advertise a property on the Site are also
            bound by the following terms, which are in addition to any other
            terms agreed to in connection with purchasing or renewing a
            subscription.
          </p>
          <br />
          <h6 id="13">13. Content, Layout and Copy.</h6>
          <p>
            All content and copy edits submitted by members are subject to
            review and approval by us in our sole discretion. We reserve the
            right to refuse to publish any content that we determine in our sole
            discretion does not meet these Terms or is otherwise unacceptable to
            us. However, we assume no duty to review content and we shall not
            have any liability for any loss or damage resulting from the design
            or positioning of the copy, properties, content and/or photographs
            or any change made to any content, photograph or copy submitted by
            any member. All content must meet these Terms and our Content
            Guidelines. We reserve the right to edit content submitted to the
            Site in a non-substantive manner solely to cause the content to
            comply with our content guidelines or formatting requirements.
            Members are responsible for reviewing and ensuring that any content
            displayed on the Site appears as the member intended.
          </p>
          <br />
          <h6 id="14">14. Uses of Our Trademarks or Logos.</h6>
          <p>
            The Company name and logo and those of The Company and our
            affiliates are registered trademarks in the United States and other
            jurisdictions around the world. We generally do not permit the use
            of our names and logos, other than as described above or with our
            prior written authorization.
          </p>
          <br />
          <h6 id="15">15. Hypertext Links. </h6>
          <p>
            We reserve the right to refuse hypertext links to, or addresses of,
            other websites from members' pages, and to remove links or web
            addresses without notice at our sole discretion. Further, we reserve
            the right to charge for hypertext links at any time.
          </p>
          <br />
          <h6 id="16">
            16. Unauthorized Payment Methods; Subscription Payments; Automatic
            Renewal of Subscription Payments.
          </h6>
          <p>
            Any violation of this term or any other unacceptable payment methods
            that may be posted on the Site may result in the immediate removal
            from the Site without notice to the member and without refund. From
            time to time, we may become aware of users attempting to conduct a
            transaction that would involve an unauthorized payment method or a
            fraudulent payment method. Ideally, we hope to be able to assist
            users in avoiding such transactions, but we assume no liability or
            responsibility to do so or to inform users of any such actual or
            suspected activity. <br />
            <br />
            Payments for subscriptions: Payment for subscription must be made to
            us in U.S. Dollars paid either by major credit or debit card, or a
            check drawn on a U.S. bank. <br />
            <br />
            Automatic Renewal of Subscriptions: For any subscription paid for by
            credit card, such subscription shall automatically renew at the
            expiration of the then-current term for an additional term of the
            same duration (as the previous term) and at the then-current
            non-promotional subscription rate. If such subscription was
            purchased by check or another form of payment other than by credit
            card (if such other payment form was permitted), such subscription
            shall not be automatically renewed. Automatic renewal applies to all
            subscriptions purchased by credit card. The automatic renewal
            feature allows your service to remain uninterrupted at the
            expiration of your then-current term. If you wish to turn off
            auto-renewal, you must log on to your account and manually turn off
            auto-renewal in your owner dashboard found on The Website, at least
            5 days prior to the expiration of the then-current term. Upon any
            such turning off auto-renewal, your subscription will remain active
            through the expiration of your then-current subscription term;
            however, your subscription will not be automatically renewed upon
            the expiration of your then-current term. If your subscription does
            not auto-renew or expires at the end of your then current
            subscription term and you desire to renew your subscription, you
            will be required to pay the then-current non-promotional
            subscription rate to renew your subscription or to activate a new
            subscription. <br />
            <br />
            If you do not turn off auto-renewal and you continue to use our
            subscription service, you re-affirm and authorize us to charge your
            credit card at the end of each subscription term for an additional
            term of the same duration as the initial term and at the
            then-current non-promotional subscription rate for the same product
            or service. <br />
            <br />
            If the product or service that you last purchased has changed in any
            way or is no longer offered, you agree and authorize us to charge
            your credit card at the renewal of your subscription term for a
            product or service that is the most similar, as determined by us, to
            the product or service that you previously purchased, even if the
            price of such product or service is not the same of the prior
            product or service that you purchased. You agree to be responsible
            for any such charges, and we reserve the right to obtain payment
            directly from you if necessary. <br />
            <br />
            If you wish to avoid billing of subscription fees for the renewal
            term to your credit card, you must turn off auto-renewal for your
            subscription at least 5 days before it renews. If you wish to change
            your credit card to be charged or if your credit card information
            otherwise changes, see The Website for FAQ information on updating
            the information in your owner dashboard, as applicable or to provide
            the new or different credit card information, as applicable, to
            provide the new or different credit card information.
          </p>
          <br />
          <h6 id="17">
            17. Subscription Term, Refund Requests, and Termination.
          </h6>
          <p>
            Subscription Term: All subscription are sold to run the full term
            that is chosen by the member. The term starts on the date that the
            member submits the full or initial (as applicable) payment and
            expires on the last date of the term chosen by the member. For
            example, for an annual subscription term, if the member submits
            payment for the subscription on July 1st, the subscription would
            expire on June 30 of the following year. <br />
            <br />
            Refund Requests: Generally, no refunds are available. All sales are
            final by The Company and or third-party sales. If you feel a refund
            is needed such requests can be reviewed, but the purchase is done
            with the understanding that there is a no refund policy and all
            sales are final. <br />
            <br />
            Refund Requests for Subscriptions: All subscription purchases are
            final and non-refundable. Subscriptions whether daily, weekly,
            monthly or with a date range that is pre-established and set up for
            recurring charges must be changed to a free version or canceled
            prior to the date and time of the charge. Once invoiced and charged
            the funds are non-refundable. Requests may be reviewed, but due to
            the nature of The Company being charged for white label services and
            other user-related costs - all transactions are final.
            <br />
            <br />
            If you renew your subscription, or if your subscription
            automatically renews under its terms of your subscription, your
            account will remain actively subscribed for the entire subscription
            period without refund. If you sell your property and no longer wish
            to be a subscriber, please change your subscription within your
            account to the free "Basic" version; however, no refund will be
            owed. <br />
            <br />
            Transfer of Subscription to a Third Party: No subscription may be
            transferred to another party. In the event of a property sale or
            change in property management, The Company will provide guidance on
            options for creating a new subscription. <br />
            <br />
            Annual subscriptions are provided as stated at the time of purchase.
            Annual subscriptions include at times 2 months of free service over
            the 1 year automatic renewal time period, so refunds are not
            provided. Such subscriptions and payments are final. Please review
            the product and plan selection prior to purchase as subscriptions
            for monthly and annual are non-refundable. Plans and features may
            change from time to time, but subscriptions will entitle you to at
            least the basic services offered at the time of purchase. Normally
            each subscription plan will evolve and include more features over
            time and Users will not be required to pay an additional fee over
            that payment period in regards to the subscription in which they are
            signed up. Under subscriptions, Users may still find products and
            services available for purchase and these products are not part of
            the subscription but can be purchased separately. Users will receive
            an original receipt, but ongoing monthly transaction receipts may be
            found in their account under "upgrade my plan". <br />
            <br />
            In the event, a User wants to cancel their subscription or
            discontinue automatic payments renewal they may do so by logging
            into their account and under settings and "Upgrade my Plan" they can
            change and manage their subscriptions of which is immediate. They
            may also email Us the contact information to the email listed in the
            Contact US section from their email in which the account is managed
            and request for a support team member to assist them. Any request
            sent via the support email is limited to at least 5 business days
            before subscriptions can be changed due to holidays, weekends, call
            volume and workload.
          </p>
          <br />
          <h6 id="18">18. Registration and Account Security.</h6>
          <p>
            User commitments <br />
            <br />
            In protecting user information users also commit to the following:
            <br />
            <br />
            You will not provide any false personal information, or create an
            account for anyone other than yourself without permission.
            <br />
            <br />
            If we disable your account, you will not create another one without
            our permission.
            <br />
            <br />
            You will not use The Website for any illegal activity of any sort.
            <br />
            <br />
            You will not use The Website if you are under 13.
            <br />
            <br />
            You will keep your contact information accurate and up-to-date.
            <br />
            <br />
            You will not share your password (or in the case of developers, your
            secret key), let anyone else access your account, or do anything
            else that might jeopardize the security of your account.
            <br />
            <br />
            You will not transfer your account (including any Page or
            application you administer) to anyone without first getting our
            written permission.
            <br />
            <br />
            If you select a username or similar identifier for your account or
            Page, we reserve the right to remove or reclaim it if we believe it
            is appropriate (such as when a trademark owner complains about a
            username that does not closely relate to a user's actual name).
          </p>
          <br />
          <h6 id="19">19. Community Payments Terms.</h6>
          <p>
            When you use The Website Payments services to pay for subscription
            services, you agree that we may communicate with you electronically
            any important information regarding your purchase or your account.{" "}
            <br />
            <br />
            Payments <br />
            <br />
            Funding and spending. When you make a payment using The Website, you
            agree to have the funds in your account prior to sending funds.{" "}
            <br />
            <br />
            You acknowledge that we use Dwolla and Stripe Connect for The
            Webiste Payments and you will be subject to Dwolla’s and or Stripe’s
            terms of service as well. Dwolla terms of service are provided
            separately when creating a Site Payments account and can be found on
            the Dwolla site as are Stripe’s. <br />
            <br />
            The Company provides access for users to use Stripe to execute
            online payment transactions. By using this feature of the Service,
            you agree to be bound by the Stripe Connected Account Agreement,
            available at{" "}
            <a href="https://stripe.com/connect/account-terms" target="_blank">
              https://stripe.com/connect/account-terms
            </a>
            . This may be updated from time to time. For the avoidance of doubt,
            if any payment you initiate using the Service does not successfully
            complete, the payee reserves the right to seek payment from you via
            or outside the Service. Any authorization you provide to make
            repeating automatic payments using the Service will remain in effect
            until canceled. <br />
            <br />
            The Company provides access for users to use PayPal to execute
            online payment transactions. By using this feature of the Service,
            you agree to be bound by the Paypal's terms of service, available at{" "}
            <a
              href="https://www.paypal.com/ga/webapps
/mpp/ua/useragreement-full"
              target="_blank"
            >
              https://www.paypal.com/ga/webapps /mpp/ua/useragreement-full.
            </a>{" "}
            This may be updated from time to time. For the avoidance of doubt,
            if any payment you initiate using the Service does not successfully
            complete, the payee reserves the right to seek payment from you via
            or outside of the Service. Any authorization you provide to make
            repeating automatic payments using the Service will remain in effect
            until canceled. <br />
            <br />
            You agree that if at any time The Company finds or has reason to
            doubt the legitimacy of your account or payments The Company can
            intervene and seize any and all funds until property identity is
            confirmed for funds to be returned to the proper owners. <br />
            <br />
            In the event, The Company suspects your account of being fraudulent
            you agree that The Company will seize all funds and hold them until
            either the property authorities and owners have been contacted and
            funds returned. <br />
            <br />
            For Services offered on a payment or subscription basis, the
            following terms apply, unless Practice Wise or its third party
            affiliate notifies you otherwise in writing. This Agreement also
            incorporates by reference and includes program ordering and payment
            terms provided to you on the website for the Services:
            <br />
            <div className="p-2">
              <ol style={{ listStyle: "lower-alpha" }}>
                <li style={{ listStyle: "lower-alpha" }}>
                  Payments will be captured at subscription to you in U.S.
                  dollars, and your account will be debited when you subscribe
                  and provide your payment information, unless stated otherwise
                  in the program ordering or payment terms on the website for
                  the Services. Membership fee is U.S. dollars five thousand and
                  there is a per transaction fee of 0.02% charge above any fees
                  incurred by any third-party vendor.{" "}
                </li>
                <li style={{ listStyle: "lower-alpha" }}>
                  You must pay with one of the following:
                  <ol style={{ listStyle: "auto" }}>
                    <li style={{ listStyleType: "auto" }}>
                      A valid credit card acceptable to Practice Wise.
                    </li>
                    <li style={{ listStyleType: "auto" }}>
                      A valid debit card acceptable to Practice Wise.
                    </li>
                    <li style={{ listStyleType: "auto" }}>
                      A valid bank account acceptable to Practice Wise.{" "}
                    </li>
                    <li style={{ listStyleType: "auto" }}>
                      Sufficient funds in a checking or savings account to cover
                      an electronic debit of the payment due. or{" "}
                    </li>
                    <li style={{ listStyleType: "auto" }}>
                      By another payment option Practice Wise provides to you in
                      writing.
                    </li>
                  </ol>
                </li>
                <li style={{ listStyle: "lower-alpha" }}>
                  If your payment and registration information is not accurate,
                  current, and complete and you do not notify us promptly when
                  such information changes, we may suspend or terminate your
                  account and refuse any use of the Services.{" "}
                </li>
                <li style={{ listStyle: "lower-alpha" }}>
                  If you do not notify us of updates to your payment method
                  (e.g., credit card expiration date), to avoid interruption of
                  your service, we may participate in programs supported by your
                  card provider (e.g., updater services, recurring billing
                  programs, etc.) to try to update your payment information, and
                  you authorize us to continue billing your account with the
                  updated information that we obtain.{" "}
                </li>
                <li style={{ listStyle: "lower-alpha" }}>
                  Practice Wise will automatically renew your annual Services at
                  the then-current rates, unless the Services are cancelled or
                  terminated under this Agreement.{" "}
                </li>
                <li style={{ listStyle: "lower-alpha" }}>
                  Additional cancellation or renewal terms may be provided to
                  you on the website for the Services.
                </li>
              </ol>
            </div>
            Fees <br />
            <br />
            Facilitator Fee. The recipient, such as a seller or a third party
            facilitating your payment transaction (“Facilitator”) may charge you
            additional service fees (“Facilitator Fee”) based on their terms and
            conditions. We recommend that you review Facilitator Fee terms
            before completing your payment. <br />
            <br />
            Reversal Fee. If a payment that you send is subject to a Reversal as
            set out in Section 6 (Transaction reversal), you may be charged a
            fee of $25.00 (“Reversal Fee”). <br />
            <br />
            Transaction Limits <br />
            <br />
            Account limits. You are limited on the amount of money that can be
            sent from your Account per transaction. The per transaction limit
            varies based on your Account type. Personal Accounts are limited to
            sending $5,000.00 per transaction. Business, Non-profit, and
            Government Accounts are limited to sending $10,000.00 per
            transaction. We reserve the right to decrease your spending limit at
            any time, for any reason. <br />
            <br />
            Increased limits. You may apply for an increased spending limit
            here. Approval is solely in The Company’s discretion and may be
            reassessed and/or revoked at any time. <br />
            <br />
            Funding Source Limitations <br />
            <br />
            Recipient Limitation. A recipient, such as a seller, may choose not
            to accept all forms of The Company funding sources. This means you
            may not be able to choose from the full range of funding sources you
            have available in your Account to complete your payment to that
            recipient. This would then be reflected in your Website Payments
            account. <br />
            <br />
            Transaction Reversal <br />
            <br />
            Debit the bank or credit union account(s) linked to your Account{" "}
            <br />
            <br />
            Suspend your Account and require your immediate payment; or <br />
            <br />
            Engage in collection efforts. <br />
            <br />
            Reversals. Some examples of ways a payment you received may be
            reversed include: (a) the sender requests a reversal of the payment,
            (b) the sender’s bank or credit union requests a reversal of the
            payment or (c) The Company decides a Dispute against you (each a
            “Reversal”). You are liable to our Financial Institution Partners
            for the full amount of any payment that you receive that is subject
            to a Reversal and the Reversal Fee if applied. <br />
            <br />
            Authorization to recover amounts due. You authorize The Company to
            recover any Reversal amounts due to our Financial Institution
            Partners by debiting your available Site balance. If you have an
            insufficient Site Payments balance, you authorize The Company and
            our Financial Institution Partners to take any of the following
            actions to recover the remaining amounts from you: <br />
            <br />
            User Conduct and Restricted activities. You agree that in connection
            with your use of The Website Services and your interactions with The
            Company you will not: <br />
            <br />
            Breach these Terms or any other applicable terms or policies of The
            Company, our Financial Institution Partners, or partner services
            that you have accepted; <br />
            <br />
            Provide false or inaccurate information to The Company, including
            identity information; <br />
            <br />
            Engage or attempt to engage in illegal or fraudulent activities;{" "}
            <br />
            <br />
            Engage in transactions involving illegal goods, including but not
            limited to counterfeit goods, stolen goods, illegal or controlled
            substances, and substances that pose a risk to consumer safety
            (including synthetics, “potpourri not for human consumption”, and
            other similar items) <br />
            <br />
            Engage in transactions involving illegal services, including but not
            limited to counterfeit services, illegal gambling, Ponzi and/or
            pyramid schemes, and money laundering; <br />
            <br />
            Engage in transactions involving debt collection services; <br />
            <br />
            Engage in transactions involving escort services; <br />
            <br />
            Engage in transactions involving the purchase, sale, or exchange of
            Virtual Currency or provide a Virtual Currency marketplace or
            exchange; <br />
            <br />
            Engage in transactions involving the purchase and/or sale of lottery
            tickets; <br />
            <br />
            Provide white label ATM services; <br />
            <br />
            Engage in the following activities without The Company’s prior
            written consent: transactions related to online gambling, or
            activities regulated by FinCEN, including money services business
            activities and payday lending; <br />
            <br />
            Attempt to receive or actually receive duplicate compensation for a
            disputed payment from the recipient (such as a seller), The Company,
            and/or your bank or credit union; or <br />
            <br />
            Engage in the activity that indicates, in the discretion of The
            Company or our Financial Institution Partners, that there may be a
            high level of risk associated with you, your Account, or any of your
            Account activity. <br />
            <br />
            Disputes with other The Company users. <br />
            <br />
            You are asking The Company to assist in resolving the dispute in its
            sole discretion and that such assistance or The Company’s decision
            may not be satisfactory to you; <br />
            <br />
            The Company’s assistance in resolving the dispute does not guarantee
            any particular outcome nor any action on The Company’s part; and{" "}
            <br />
            <br />
            You release The Company and our officers, directors, agents,
            employees, and suppliers from all claims, demands, and damages of
            any kind arising out of your dispute with a seller and The Company's
            review of your Dispute. <br />
            <br />
            Within 45 days of the original transaction, email or support as
            listed in the Contact Us section with the following information:{" "}
            <br />
            <br />
            The Account numbers and names of both parties, if available; <br />
            <br />
            The transaction ID number; <br />
            <br />
            The amount of the transaction; and <br />
            <br />
            The details of your disagreement with the recipient, including any
            steps already taken to resolve the issue and copies of supporting
            documentation (such as email correspondence, receipts, shipping
            confirmation, etc.). <br />
            <br />
            Provide any additional information or documentation that we may
            request. Once The Company has decided the outcome of the Dispute,
            the parties will be notified of the decision. You are required to
            comply with the decision and complete any actions required by such a
            decision. <br />
            <br />
            Dispute resolution. <br />
            <br />
            You understand and agree that The Company is not responsible for the
            goods or services that you pay for using The Company Payments. Each
            seller that you purchase from is responsible for providing the goods
            and services that you purchase and for providing all customer
            service related to those goods and services. We recommend that you
            review a seller’s policies before completing your purchase. You are
            responsible for resolving any disputes that you may have with a
            seller. If you have exhausted all options for resolving a dispute
            with a seller, you may choose to file a dispute claim with The
            Company by emailing the contact information listed in the Contact Us
            section. <br />
            <br />
            Your liability and actions we may take. <br />
            <br />
            Suspending your access to your Account and/or The Company Services;{" "}
            <br />
            <br />
            Suspending your access to your funds held in a Holding Account for
            up to 90 days; Taking action as set out in Section 6 (Transaction
            reversal) to recover amounts that you owe; <br />
            <br />
            Closing your Account; <br />
            <br />
            Contacting The Company users or third parties who have purchased
            goods or services from you, contacting your bank, and/or warning
            other The Company users, law enforcement, or other impacted third
            parties of your actions; <br />
            <br />
            Refusing to provide The Company Services to you in the future; and{" "}
            <br />
            <br />
            Taking legal action against you. <br />
            <br />
            Your liability. <br />
            <br />
            You are responsible for all Reversals, claims, fees, fines,
            penalties, and other liability incurred by The Company, our
            Financial Institution Partners, other Site users, or third parties
            arising from your breach of these Terms or your use of the Site
            Payments. You agree to reimburse The Company, our Financial
            Institution Partners, other Site users, or third parties for any and
            all such liability. <br />
            <br />
            Actions we may take. If we determine, in our sole discretion, that
            you may have breached these Terms, that you or your Account activity
            present risk or security concerns, or if we are unable to verify
            your identity, we may take actions to protect The Company and our
            users, our Financial Institution Partners, or other third parties
            from Reversals, claims, fines, penalties, and any other liability.{" "}
            <br />
            <br />
            Notice of limit or suspension. We will provide you with notice if we
            take any of the above actions against your Account. If we limit or
            suspend your Account, we will provide you with notice of our action.{" "}
            <br />
            <br />
            Unauthorized transactions and Error resolution. <br />
            <br />
            Your name and Account number, <br />
            <br />
            The nature of the suspected Error and why you believe it is an
            Error, and <br />
            <br />
            The dollar amount of the suspected Error. <br />
            <br />
            Notify The Company of Account Errors. Contact The Company
            immediately if you think that: (a) your Account has been accessed
            without your authorization, (b) a transaction that you did not
            authorize has occurred, (c) a transaction has been processed
            incorrectly to or from your Account, or (d) your Account statement
            contains an error regarding your transaction history (each, an
            Error). If you give someone access to your Account and that person
            conducts transactions without your authorization, these transactions
            are not considered Errors, unless you are the victim of a phishing
            attack or similar exploit. We must hear from you within 60 days
            after we provided the Account statement on which the suspected Error
            first appeared. <br />
            <br />
            If you notify us by phone, we may require that you send us your
            inquiry by email within 10 business days. <br />
            <br />
            Investigation by The Company. We will determine whether an Error
            occurred within 10 business days after you notify us and will
            correct any Error promptly. If we need more time, we may take up to
            45 days to investigate. If we decide to do this, we will credit your
            Account within 10 business days for the amount of the suspected
            Error. If we ask you to send your inquiry by email and we do not
            receive it within 10 business days, we may not credit your Account.
            For Errors involving new Accounts, we may take up to 90 days to
            investigate and may take up to 20 business days to credit your
            Account.
            <br />
            <br />
            Investigation results. The Company will tell you the results within
            3 business days after completing our investigation. If we decide
            that there was no Error, we will provide you a written explanation.
            You may ask for copies of the documents that we used in our
            investigation. Termination. <br />
            <br />
            By you. You may stop using the Site Payments at any time. You may
            also close your Account at any time, as long as you have no pending
            transactions or open Disputes. <br />
            <br />
            By us. We may close your Account or terminate your access to The
            Website Payments without liability, for any reason, upon notice to
            you. Reasons for termination may include but are not limited to,
            your violation of these Terms or any other applicable terms or
            policies of The Company or our Financial Institution Partners,
            Account inactivity, or The Company’s assessment that you pose an
            unacceptable risk to the platform, based on our confidential risk
            and security criteria. The Company also reserves the right to modify
            or terminate the Site Payments at any time, for any reason. <br />
            <br />
            Disputes with The Company. <br />
            <br />
            Binding arbitration. You, The Company, and our Financial Institution
            Partners (the “Parties") agree that upon the election of either of
            the Parties, any past, present, or future dispute relating in any
            way to your Account, or any other past, present, or future
            relationship or transaction between the Parties, will be resolved by
            binding arbitration as discussed below, and not through litigation
            in any court. Such disputes are called "Claims" for purposes of this
            agreement. This binding agreement to arbitrate applies regardless of
            whether the dispute is between you and us jointly or separately.
            This arbitration agreement is entered into pursuant to the Federal
            Arbitration Act, 9 U.S.C. §§ 1-16. The arbitration shall be
            conducted by a single neutral arbitrator acting under the
            administration of United States Arbitration and Mediation, and in
            accordance with the then-applicable United States Arbitration and
            Mediation Rules of Arbitration. Unless the Parties agree otherwise,
            or unless the arbitration administrator's rules or law require
            otherwise, the arbitration shall be held in New York, NY. <br />
            <br />
            The arbitrator will decide the Claim in accordance with all
            applicable law, including recognized principles of equity and
            statutes of limitations, and will honor all privileges recognized by
            law. The arbitrator will have the authority to award to a Party any
            damages or relief provided for under applicable law. The
            arbitrator's decision shall be final and legally binding and may be
            enforced by any court having jurisdiction. <br />
            <br />
            No class actions or similar process, and no joinder or consolidation
            of any Claim with a Claim of any other person or entity shall be
            allowable in arbitration, without the written consent of both
            Parties. The arbitrator shall has no authority to entertain any
            Claim on behalf of a class, group, person, or entity who is not a
            named party to the arbitration, nor shall any arbitrator has
            authority to make any award for the benefit of, or against, any
            class, group, person, or entity who is not a named party to the
            arbitration. In the event that there is a dispute about whether
            limiting arbitration to non-class proceedings, or to the named
            parties, is enforceable under applicable law, then that question
            shall be resolved by a court rather than by an arbitrator; and to
            the extent it is determined that resolution of a Claim must proceed
            on a class basis, it shall so proceed in a court of competent
            jurisdiction rather than in arbitration. If you elect to proceed
            with respect to any Claim by an individual action in a small claims
            court, or its equivalent, instead of arbitration, we will not
            object, however, any such action shall be taken in small claims
            court in New York. <br />
            <br />
            If the arbitrator or arbitration administrator would impose filing
            fees or other administrative costs on you, we will reimburse you,
            upon request, to the extent such fees or costs would exceed those
            that you would otherwise have to pay if you were proceeding instead
            in a court. We will also pay additional fees or costs if required to
            do so by the arbitration administrator's rules or applicable law.
            Apart from the foregoing, each Party will be responsible for any
            other fees or costs, such as attorney fees that the Party may incur.
            If you consider that you are unable to afford any fees or costs that
            would be yours to pay, you may request that we pay or reimburse
            them, and we will consider your request in good faith. <br />
            <br />
            Arbitration with respect to a Claim is binding and neither Party
            will have the right to litigate that Claim in a court. In
            arbitration, the Parties will not have the same rights that apply in
            courts, such as the right to a trial by judge or jury and the right
            to participate or be represented in proceedings brought by others
            such as class actions or similar proceedings. In addition, the right
            to discovery and the right to appeal may also be limited or
            eliminated in arbitration. All of these judicial rights are waived
            with respect to Claims that the Parties elect to arbitrate. General
            terms. <br />
            <br />
            Indemnification. You agree to defend, indemnify and hold harmless
            The Company, our Financial Institution Partners, and our and their
            respective officers, directors, agents, employees, and suppliers
            from any third party claims, actions, proceedings, and suits and
            related liabilities, damages, settlements, penalties, fines, costs
            or expenses (including reasonable attorneys' fees and other
            litigation expenses) arising from: (a) your violation of these Terms
            or any other applicable terms or policies of The Company or our
            Financial Institution Partners; (b) your use of The Company
            Payments; or (c) your negligence or willful misconduct; and/or (d)
            your actual or alleged violation of any third party rights, or any
            applicable laws, regulations or rules. <br />
            <br />
            Limitation of liability. IN NO EVENT SHALL THE COMPANY, OUR
            FINANCIAL INSTITUTION PARTNERS, OR OUR OR THEIR RESPECTIVE OFFICERS,
            DIRECTORS, AGENTS, EMPLOYEES, OR SUPPLIERS BE LIABLE TO YOU OR ANY
            THIRD PARTY UNDER ANY CIRCUMSTANCES FOR ANY INDIRECT, CONSEQUENTIAL,
            SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR LOSSES, INCLUDING BUT NOT
            LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR
            OTHER INTANGIBLE LOSSES INCURRED IN CONNECTION WITH: (i) THESE
            TERMS, (ii) YOUR USE OF, INABILITY TO USE, OR UNAVAILABILITY OF THE
            COMPANY PAYMENTS, OR (iii) ANY GOODS OR SERVICES PURCHASED,
            RECEIVED, OR PAID FOR USING THE SITE PAYMENTS, UNDER ANY THEORY OF
            LIABILITY OR CAUSE OF ACTION WHETHER IN TORT (INCLUDING NEGLIGENCE),
            CONTRACT, OR OTHERWISE, REGARDLESS OF WHETHER THE COMPANY OR OUR
            FINANCIAL INSTITUTION PARTNERS HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES. IN NO EVENT SHALL THE COMPANY OR EACH OF OUR
            FINANCIAL INSTITUTION PARTNERS’ LIABILITY AND THE LIABILITY OF OUR
            AND THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, AND
            SUPPLIERS EXCEED THE FEES THE COMPANY HAS RECEIVED FROM YOU THROUGH
            YOUR USE OF THE COMPANY SERVICES. THE COMPANY AND OUR FINANCIAL
            INSTITUTION PARTNERS SHALL NOT BE JOINTLY LIABLE FOR ANY MATTERS
            HEREUNDER. THIS LIMITATION OF LIABILITY SECTION SHALL APPLY TO
            FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.{" "}
            <br />
            <br />
            No warranty. THE COMPANY SERVICES ARE PROVIDED "AS IS" AND “AS
            AVAILABLE”, WITHOUT ANY REPRESENTATION OF WARRANTY, WHETHER EXPRESS,
            IMPLIED, OR STATUTORY. USE THE COMPANY SERVICES IS AT YOUR OWN RISK.
            THE COMPANY, OUR FINANCIAL INSTITUTION PARTNERS, AND OUR AND THEIR
            RESPECTIVE OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, AND SUPPLIERS,
            SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. NEITHER The
            Company NOR OUR FINANCIAL INSTITUTION PARTNERS HAVE CONTROL OF, OR
            LIABILITY FOR, ANY PRODUCTS OR SERVICES THAT ARE PAID FOR USING THE
            COMPANY SERVICES AND CANNOT ENSURE THAT ANY THIRD PARTY YOU TRANSACT
            WITH WILL COMPLETE THE TRANSACTION. NEITHER THE COMPANY NOR OUR
            FINANCIAL INSTITUTION PARTNERS REPRESENT OR WARRANT THAT THE SITE
            Payments WILL MEET YOUR REQUIREMENTS, BE CONTINUOUS, UNINTERRUPTED,
            SECURE, TIMELY, OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED. NO
            ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
            THE COMPANY OR THROUGH THE SITE Payments WILL CREATE ANY WARRANTY
            NOT EXPRESSLY STATED HEREIN. NEITHER THE COMPANY NOR OUR FINANCIAL
            INSTITUTION PARTNERS SHALL BE RESPONSIBLE FOR ANY SERVICE
            INTERRUPTIONS OR SYSTEM FAILURES THAT MAY AFFECT THE PROCESSING,
            COMPLETION, OR SETTLEMENT OF SITE SERVICES TRANSACTIONS. THIS
            DISCLAIMER OF WARRANTY SECTION SHALL APPLY TO THE FULLEST EXTENT
            PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. <br />
            <br />
            Force majeure. You understand and agree we will not be held
            responsible for any losses or damages resulting from the suspension
            of service due to extraordinary events or circumstances beyond our
            control. In such an event, The Company may suspend The Company
            Services and access to your Account and our Financial Institution
            Partners may suspend service and access to funds in a Holding
            Account. <br />
            <br />
            Assignment. You may not transfer or assign any rights or obligations
            you have under these Terms. The Company and our Financial
            Institution Partners reserve the right to transfer or assign any
            rights or obligations under these Terms at any time. <br />
            <br />
            Applicable law. You agree that the laws of the State of New York,
            without regard to principles of conflict of laws, govern these Terms
            and any claim or dispute that has arisen or may arise between you
            and The Company and/or our Financial Institution Partners. <br />
            <br />
            Complete agreement, no waiver, and survival. These Terms, along with
            any applicable terms and policies of The Company or our Financial
            Institution Partners that you have agreed to, set forth the entire
            understanding between you, The Company, and our Financial
            Institution Partners with respect to The Company Payments. Any
            failure of The Company or our Financial Institution Partners to
            enforce any right or provision of these Terms shall not constitute a
            waiver of such right or provision. Any provision of these Terms held
            to be invalid or unenforceable under applicable law shall be struck,
            and the remaining provisions will continue in full force and effect.
            Sections 1 (Using The Website), 2 (Consent to receive electronic
            Communications), 6 (Transaction reversal), 8 (White Label Accounts),
            10 (Disputes with other Site users), 11 (Your liability and actions
            The Company and the Financial Institution Partner may take), 14
            (Disputes with The Company), and 15 (General terms), as well as any
            other terms which by their nature should survive, will survive the
            termination of these Terms.
          </p>
          <br />
          <h6 id="20">20. Consent to receive electronic Communications.</h6>
          <p>
            Communications. By opening an Account and using The Website, you
            consent to receive all Communications from The Company
            electronically and you confirm that you can access, receive and
            retain such Communications. “Communications” means all
            communications, terms, disclosures, notices, and statements that we
            provide to you in connection with your Account and your use of The
            Website Payments. We provide Communications to you by posting them
            on The Website, sending to your Site account or by emailing them to
            you at the email address associated with your Account. <br />
            <br />
            Notices to you. Communication will be considered to be received by
            you within 24 hours after the time we post it to our website or
            email it to you. <br />
            <br />
            Hardware and software requirements. In order to access and retain
            Communications electronically, you need the following hardware and
            software: <br />
            <br />
            A computer, laptop, smartphone, or another device, that is
            Internet-enabled <br />
            <br />
            A valid email account associated with your Account; <br />
            <br />
            A web browser which includes 128-bit encryption, such as current
            versions of Chrome, Internet Explorer, Firefox, or Safari, with
            cookies enabled; and <br />
            <br />
            Data storage to save Communications electronically or an installed
            printer to print them. <br />
            <br />
            Keeping your contact information updated. You must keep your email
            address updated in order to receive Communications from The Company
            electronically. To update the email address associated with your
            Account, log into your Account on The Website and access the
            settings menu. <br />
            <br />
            Requesting paper copies. You may request a paper copy of a
            Communication that we provided to you electronically by contacting
            us as set out in (How to contact us regarding electronic
            Communications). We will send it to the mailing address on file for
            your Account. The Company may charge you a fee of $25.00 for each
            paper copy of a Communication sent to you. <br />
            <br />
            Withdrawing consent. You may withdraw your consent to receive
            Communications electronically by contacting us as set out in (How to
            contact us regarding electronic Communications). If you withdraw
            your consent, The Company may prohibit you from using The Website
            Payments and may close your Account. <br />
            <br />
            How to contact us regarding electronic Communications. If you have
            any questions regarding our policy on electronic Communications,
            please email us at the contacts listing in the Contact Us section.
          </p>
          <br />
          <h6 id="21">
            21. Use of Third Party contracts notices and other documentation and
            E-signature.{" "}
          </h6>
          <p>
            Users agree that all e-signatures provided to each other or The
            Company are binding and as if they used their own pen signatures.
            All contracts and commitments and binding. <br />
            <br />
            USER REPRESENTS THAT USER HAS ALL RIGHTS REQUIRED TO MAKE AVAILABLE
            AND DISTRIBUTE THE MATERIALS WITHIN CONTRACTS. EXCEPT FOR SUCH
            REPRESENTATION, THE MATERIALS IS PROVIDED “AS IS” AND “AS AVAILABLE”
            AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
            PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
            DISCLAIMED. USER CONFIRMS THEY HAVE NOT STOLEN OR REPRODUCED ANY
            CONTRACTS WITHOUT PROPER PAYMENT OR APPROVAL FROM THIRD PARTIES FOR
            THE USE OF CONTRACTS AND NOTICES. <br />
            <br />
            THE COMPANY HAS NOT PROVIDED IT DOCUMENT AND E-SIGNATURE SERVICES
            FOR THE PURPOSE OF STEALING AND REPRODUCES THIRD PARTY CONTRACTS.
            USER CAN LOSE THEIR ABILITY TO ACCESS THE SITE IN THE EVENT THEY ARE
            FOUND TO BE PARTICIPATING IN SUCH FRAUDULENT ACTIVITIES.
          </p>
          <br />
          <h6 id="22">22. Credit, Screenings and Background Checks.</h6>
          <p>
            The Company is not a credit bureau and does not control the contents
            of credit reports, background checks and other products for
            screening including reports obtained through the Service. We are NOT
            responsible for the contents of any credit report or background
            check report or any credit score, whether correct or incorrect.
            Credit reports and background check reports can contain information
            that is incorrect, incomplete, or not up to date, and reports are
            not a substitute for carefully reviewing and independently verifying
            all information contained in an application and interviewing a
            prospective client. If you believe that any information contained in
            your credit report or background check report is inaccurate or
            incomplete, you have the right to dispute it. To dispute the
            contents of your credit report, please contact the appropriate third
            party vendor in which the service was purchased.
          </p>
          <br />
          <h6 id="23">23. User Accounts. </h6>
          <p>
            Use of the Sites and any products and services purchased or rendered
            therein, may be used through User Accounts. Such User Accounts shall
            be accessible through the use of a combination of a unique User ID
            and a unique, secret password as well as potentially other optional
            authentication methods as may or may not be provided by us (the User
            ID, Password, and any other authentication feature together being
            referred to as the “Login Credentials”). <br />
            <br />
            You represent that all information provided by you for Login
            Credentials is accurate and current. Users are obligated to choose
            their own User ID and Password in accordance with the rules relating
            thereto. <br />
            <br />
            You agree that you are solely responsible for all use of the Service
            under your Login Credentials and that you shall not disclose such to
            any person whatsoever nor permit another person to use the website
            via your User Account as also provided in the User Conduct provision
            herein. <br />
            <br />
            We do not guarantee the availability or use of any particular User
            ID. User IDs and other identifying Login Credentials or usernames
            must not be in any way unlawful, illegal, fraudulent or harmful,
            harassing or infringe any third party’s legal rights. <br />
            <br />
            We reserve the right to terminate User Accounts for any violation of
            these Terms of Use in our sole discretion.
          </p>
          <br />
          <h6 id="24">24. User Content. </h6>
          <p>
            You are solely responsible for any written or electronic
            communications that you submit by or through the Sites, including
            but not limited to any information relating to your account, any
            statements, writings, content, copyrightable subject matter, audio,
            visual or audiovisual materials, graphical images, text or other
            content (“User Content”), and we may refuse to post or may delete
            any User Content for any or no reason, including User Content that,
            in our sole judgment, violates these Terms of Use or which may be
            offensive, illegal or violates the rights of any person or entity,
            or harms or threatens the safety of any person or entity. <br />
            <br />
            You represent, warrant and covenant to us that all User Content you
            provide, including, but not limited to information related to you,
            is true and accurate to the best of your knowledge. You may not
            post, transmit, or share User Content that you did not create or
            that you do not have permission to post. <br />
            <br />
            We assume no responsibility for monitoring the Sites to detect
            inappropriate User Content or conduct. We reserve the right to, any
            time we choose, in our sole discretion, monitor the Sites and remove
            any User Content we deem to violate these Terms of Use. By doing so,
            we do not assume any further responsibility for any removed User
            Content, and we will not have any future or continuing obligation to
            monitor, modify or remove any inappropriate User Content. You are
            solely responsible at your sole cost and expense for creating backup
            copies and replacing any User Content you post or store on the Sites
            or provide to us. <br />
            <br />
            You assume full responsibility for, and agree to indemnify us
            against any liability arising out of, any and all User Content that
            you post on or through the Sites or its related services, as well as
            for any material or information that you transmit to other users of
            the Sites and for your interactions with other users. <br />
            <br />
            We do not assert any ownership over your User Content; rather, as
            between us and you, subject to the rights granted to us in these
            Terms, You retain full ownership of all of your User Content and any
            intellectual property rights or other proprietary rights associated
            with your User Content. You hereby grant to us a worldwide,
            non-exclusive, royalty-free, irrevocable, transferable, and license
            to publicly display, sell, and distribute such User Content;
            provided, however, that we shall maintain the confidentiality of any
            information solicited by the Sites from you if designated as
            confidential by the Sites (except that, to the extent so indicated
            on the Sites at the time of submission. <br />
            <br />
            You represent, warrant and covenant that, with respect to all of
            your User Content, you have all rights necessary to grant the
            foregoing rights and licenses, and that the User Content does not
            violate any law or the privacy rights, publicity rights, copyrights,
            contract rights, or any other rights of any other person or entity.{" "}
            <br />
            <br />
            You shall indemnify, defend and hold us, our partners, our
            affiliates, and our users harmless from and against any claim,
            demand or cause of action asserted by any third party alleging,
            directly or indirectly, that your User Content violates any law or
            violates or infringes upon any third party intellectual property
            rights of any kind or nature, of that such User Content was
            inaccurate or misleading or in any way did not properly represent
            you.
          </p>
          <br />
          <h6 id="25">25. User Conduct. </h6>
          <p>
            You agree to only use the Sites for its intended purposes. In
            addition, you agree not to use the Sites to: <br />
            <br />
            harvest or collect email addresses or other contact information of
            other users from the Sites by electronic or other means for the
            purposes of sending unsolicited emails or other unsolicited
            communications; <br />
            <br />
            any way harm, damage, disable, overburden or impair the Sites;{" "}
            <br />
            <br />
            use automated scripts and/or technologies to collect information
            from or otherwise interact with the Sites; upload, post, transmit,
            share, store or otherwise make available any content that we deem to
            be harmful, threatening, unlawful, defamatory, infringing, abusive,
            inflammatory, harassing, vulgar, obscene, fraudulent, invasive of
            privacy or publicity rights, hateful, or racially, ethnically or
            otherwise objectionable; <br />
            <br />
            register for more than one user account as an individual or more
            than one user account for any legally incorporated business entity,
            register for a user account on behalf of an individual other than
            yourself or a legally incorporated business entity to which you have
            legal authority to manage such user account; or register for a user
            account on behalf of any group or entity; <br />
            <br />
            upload, post, transmit, share or otherwise make available any
            unsolicited or unauthorized advertising, solicitations, promotional
            materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,”
            or any other form of solicitation; <br />
            <br />
            upload, post, transmit, share, store or otherwise make publicly
            available on the Sites any private information of any third party,
            including, addresses, phone numbers, email addresses, Social
            Security numbers and credit card numbers; solicit personal
            information from anyone under the age of eighteen (18) or solicit
            passwords or personally identifying information for commercial or
            unlawful purposes; <br />
            <br />
            upload, post, transmit, share or otherwise make available any
            material that contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment; <br />
            <br />
            upload, post, transmit, share, store or otherwise make available
            content that would constitute, encourage or provide instructions for
            a criminal offense, violate the rights of any party, or that would
            otherwise create liability or violate any local, state, national or
            international law; <br />
            <br />
            use or attempt to use another's account, service or system without
            authorization from Us, or create a false identity on the Sites;{" "}
            <br />
            <br />
            upload, post, transmit, share, store or otherwise make available
            content that, in our sole judgment, is objectionable or which
            restricts or inhibits any other person from using or enjoying the
            Sites, or which may expose us or our users to any harm or liability
            of any type; <br />
            <br />
            circumventing or modifying, attempting to circumvent or modify, or
            encouraging or assisting any other person in circumventing or
            modifying any security technology or software that is part of the
            Sites; <br />
            <br />
            covering or obscuring the advertisements on any Sites page via
            HTML/CSS or any other means; <br />
            <br />
            any automated use of the system, such as, but not limited to, using
            scripts to inappropriately add or take away information to your
            account or any other profile of another user or send comments or
            messages; <br />
            <br />
            interfering with, disrupting, or creating an undue burden on the
            Sites or the networks or services connected to the Sites; <br />
            <br />
            using the account, username, or password of another user at any time
            or disclosing your password to any third party or permitting any
            third party to access your account; <br />
            <br />
            selling or otherwise transferring your account;
          </p>
          <br />
          <h6 id="26">26. Paid Promotions. </h6>
          <p>
            We do not give refunds or partial refunds for any paid promotions.
          </p>
          <br />
          <h6 id="27">27. Credit Checks and Background Reporting. </h6>
          <p>
            As part of the Service we have partnered with third-party credit and
            background reporting agencies. During this process we may ask for
            personally identifiable information (including Social Security
            Number) that we will share with our third-party reporting agencies
            for the purposes of generating those reports. Any information we
            gather from you relative to credit and background reports will not
            be stored by us (except temporarily to facilitate each specific
            report) nor will this information be used for any other purpose than
            facilitating these credit and background reports. We are not
            responsible for the contents of any credit report, whether correct
            or incorrect. Credit and background reporting is provided by
            third-parties and may not be available at all times due to reasons
            beyond our control. The screening fee is non-refundable and each
            screening is only good for use on a single application and cannot be
            transferred to applications. If you have any questions regarding
            these services or the reports contact us.
          </p>
          <br />
          <h6 id="28">28. Dwolla's Term of Services</h6>
          <p className="mb-1">
            In order to use the payment functionality of our application, you
            must open a "Dwolla Account" provided by Dwolla, Inc. and you must
            accept the{" "}
            <a href="https://www.dwolla.com/legal/tos" target="_blank">
              Dwolla Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://www.dwolla.com/legal/privacy" target="_blank">
              Privacy Policy.
            </a>{" "}
            Any funds held in or transferred through your Dwolla Account are
            held or transferred by Dwolla's{" "}
            <a
              href="https://www.dwolla.com/legal/about-our-financial-institution-partners/"
              target="_blank"
            >
              financial institution partners.
            </a>{" "}
            You must be at least 18 years old to create a Dwolla Account. You
            authorize us to collect and share with Dwolla your personal
            information including full name, date of birth, social security
            number, physical address, email address and financial information,
            and you are responsible for the accuracy and completeness of that
            data. You understand that you will access and manage your Dwolla
            Account through our application, and Dwolla account notifications
            will be sent by us, not Dwolla. We will provide customer support for
            your Dwolla Account activity, and can be reached at:
          </p>
          <ul>
            <li>
              <a href="https://practicewisedr.com" target="_blank">
                https://practicewisedr.com
              </a>
            </li>
            <li>support@practicewisedr.com</li>
            <li>+1-917-301-2522</li>
          </ul>
          <br />
          <h6 id="29">29. Identity Verification.</h6>
          <p>
            To ensure the safety and protection of our customers, prior to
            allowing access to certain features of the service, we require
            identity verification either directly with us or through third-party
            identification verification services. Identity verification may
            require further information based on your credit and account
            profile. For authID and Experian PreciseID verification, you
            acknowledge and understand that you are providing ‘written
            instructions’ to us under the Fair Credit Reporting Act authorizing
            us to obtain information from your personal credit profile or other
            information from Experian. You authorize us to obtain such
            information solely to confirm your identity. NOTHING HEREIN SHALL BE
            CONSTRUED TO REQUIRE US TO VERIFY THE IDENTITY OF ANY USER ON THE
            WEBSITE NOR WILL WE HAVE ANY LIABILITY FOR FAILURE TO VERIFY ANY
            USER’S IDENTITY. <br />
          </p>
          Contact Us <br />
          <ul>
            <li>
              {" "}
              If you have any questions or requests regarding this Privacy
              Policy or your Personal Information, please contact us at:
              Practice Wise
            </li>
            <li>Attn: Customer Satisfaction Officer</li>

            <li>Address: P.O. Box 20216, Brooklyn, NY 11202 </li>
            <li>E-mail address: support@practicewisedr.com</li>
            <li> Telephone: 1-917-301-2522</li>
          </ul>
          <br />
        </div>
        {/* </section> */}
      </div>

      <Footer />
    </>
  );
};

export default Terms;
