import React, { Component } from "react";

// Images
import lineBg from "../../images/appointment/line-bg.png";
import { Link } from "react-router-dom";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/wave-orange.png";
import ptImg3 from "../../images/shap/wave-blue.png";
import ptImg4 from "../../images/shap/circle-orange.png";

class JoinNowSection extends Component {
  render() {
    return (
      <>
        <section className="section-area account-wraper1">
          <div className="container-fluid">
            <div
              className="appointment-inner section-sp2"
              style={{
                backgroundImage: "url(" + lineBg + ")",
                backgroundRepeat: " no-repeat",
                backgroundPosition: "20px 140px",
              }}
            >
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-10 col-lg-10 col-md-10 text-white text-center">
                    <h3>JOIN TODAY</h3>
                    <p>
                      There's no reason you can't succeed with your medical
                      practice. You have the passion. We have the expertise.
                      It's gathered here, in one place ready to help you
                      navigate your business challenges and reach your
                      professional and personal goals. There's no telling how
                      far you can go, So why not start now by joining our
                      network and fulfilling your core business needs? call or
                      visit us online. And let our best practices contribute to
                      your best life.
                    </p>
                    <Link to="/on-boarding" className="btn btn-primary shadow">
                      Join Now
                    </Link>
                  </div>
                  {/* <div className="col-xl-7 col-lg-6 col-md-6">
                    <img src="/src/images/gallery/toothImg.jpg" alt="" />
                  </div> */}
                </div>
              </div>
              <img className="pt-img1 animate1" src={ptImg1} alt="" />
              <img className="pt-img2 animate-wave" src={ptImg2} alt="" />
              <img className="pt-img3 animate-wave" src={ptImg3} alt="" />
              <img className="pt-img4 animate2" src={ptImg4} alt="" />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default JoinNowSection;
