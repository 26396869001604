import React, { Component } from "react";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import circleDots from "../../images/shap/circle-dots.png";
import servicesPic1 from "../../images/banner/solution.jpg";
import TeethRight from "../../images/shap/dr-logo.png";
import TeethLeft from "../../images/shap/dr-logo.png";

class Solution extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Solutions</h1>
                  {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Service Details
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={TeethLeft} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-wave" src={TeethRight} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-30">
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h3 className="title mb-15">
                        Best Practices for starting a Medical Practice. Finally.
                      </h3>
                      <p>
                        Congratulations on completing medical school! It’s a
                        tremendous personal achievement. You may already be
                        thinking about starting your own practice. If so, you’re
                        probably also having major concerns about what it takes
                        to be successful on your own.
                      </p>
                      <p>
                        The truth is, starting and running a successful,
                        rewarding medical practice requires numerous skills you
                        didn’t learn in medical school, and affords ample
                        opportunities for making costly mistakes that can set
                        back your practice and affect you personally.
                      </p>
                      <p>Don’t let this keep you from realizing your goals.</p>
                      <p>
                        The world needs more great physicians. We make it easier
                        for you to be one of them.
                      </p>

                      <h3 className="title mb-15">
                        When you go out on your own, don’t do it alone.
                      </h3>

                      <p>
                        PracticeWise is the first of its kind: a dedicated cross
                        disciplinary platform of business experts that provides
                        best practices and critical support elements for medical
                        practices and the physicians who run them, all in one
                        simple, easy-to-join network.
                      </p>

                      <p>
                        When you consider all the time, money and effort you’ve
                        invested in your medical skills, why entrust the future
                        of your practice to your skills in accounting,
                        insurance, bookkeeping, taxation, marketing, real
                        estate, banking, and financial planning?
                      </p>

                      <p>
                        Having a team of experts to guide you is the difference
                        between running a business and letting a business run
                        you. The partnerships you gain put you in charge and on
                        the path to building a highly productive, lean and very
                        profitable practice. PracticeWisedr.com lets you focus
                        on the things you need to in order to become a great
                        physician.
                      </p>
                    </div>
                    {/* <div className="row align-items-center">
                      <div className="col-md-6 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>Then along come two they</li>
                          <li>That’s just a little bit more than</li>
                          <li>Standard dummy text ever since</li>
                          <li>Simply dummy text of the printing</li>
                          <li>Make a type specimen book</li>
                        </ul>
                      </div>
                      <div className="col-md-6 mb-30">
                        <div className="skillbar-box mb-30">
                          <h6 className="title">Advanced Technology</h6>
                          <div className="skillbar appear">
                            <p
                              className="skillbar-bar"
                              style={{ width: "96%" }}
                            ></p>
                            <span className="skill-bar-percent">96%</span>
                          </div>
                        </div>
                        <div className="skillbar-box mb-30">
                          <h6 className="title">Certified Engineers</h6>
                          <div className="skillbar appear">
                            <p
                              className="skillbar-bar"
                              style={{ width: "79%" }}
                            ></p>
                            <span className="skill-bar-percent">79%</span>
                          </div>
                        </div>
                        <div className="skillbar-box mb-0">
                          <h6 className="title">6 years Experience</h6>
                          <div className="skillbar appear">
                            <p
                              className="skillbar-bar"
                              style={{ width: "75%" }}
                            ></p>
                            <span className="skill-bar-percent">75%</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <div className="ttr-media mb-30">
                        <img src={servicesPic1} className="rounded" alt="" />
                      </div>
                      <div className="clearfix">
                        <div className="head-text mb-30">
                          <h4 className="title mb-10">
                            Leverage our expertise.
                          </h4>
                          <h6>
                            Reduce debt quickly while boosting your credit
                            score.
                          </h6>
                          <p>
                            There’s a right way and a wrong way to be in debt.
                            Most medical students graduate with multiple student
                            loans totaling hundreds of thousands of dollars of
                            debt. Mismanaged, it can become crippling and ruin
                            your credit. Our Accelerated Debt Reduction program
                            manages debt the right way: you get out of debt
                            sooner, save tens of thousands in interest payments,
                            and can use that repayment period to boost your
                            credit score.
                          </p>
                        </div>
                        <Accordion
                          defaultActiveKey="0"
                          className="accordion ttr-accordion1"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Applying What Continues To Work
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul className="px-3">
                                <li style={{ listStyleType: "disc" }}>
                                  Eliminate the pain and get out of debt faster.{" "}
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Avoid overpaying hundreds of thousands of
                                  dollars in interest
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Protect your credit score from mismanaged debt
                                  asphyxiation.{" "}
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Add polish to financials and protect your assets.
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                Having a trusted CPA firm familiar with your
                                industry handle your personal and business taxes
                                frees you up to focus on patients and elevate
                                your craft. When your name is on the door, it’s
                                nice to know that your books are tight and taxes
                                in check. Plus, a certified letter from your CPA
                                firm makes you look good to creditors and
                                lenders, which is key to future growth.
                              </p>
                              <ul className="px-3">
                                <li style={{ listStyleType: "disc" }}>
                                  Higher quality financial statements.
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Lower taxes.
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Greater stability and higher profit margins.
                                </li>
                              </ul>

                              <p>
                                With a high-caliber law firm in your corner,
                                you’ll have maximum flexibility and protection
                                regarding contractual or potential commercial
                                matters. Don’t negotiate directly with a
                                landlord. Use an attorney to find savings. For
                                instance, have them ask for a few months free
                                rent until your practice gains momentum. It’s
                                their expertise. You may not always need legal
                                help, but isn’t it nice to know they’re around
                                when you do?
                              </p>
                              <ul className="px-3">
                                <li style={{ listStyleType: "disc" }}>
                                  Commercial real estate leasing
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Partnership agreements
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Acquisition or divestiture of a practice
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Malpractice matters
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              Find savings within insurance.
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                Cash flow is the lifeblood of your practice. You
                                can add to it by lowering your insurance
                                premiums. Our platform gives you access to
                                insurance brokers who have realized significant
                                insurance cost savings for clients, in some
                                cases as much as 20% – 30%. Plus, they can
                                provide the coverage you need for the rest of
                                your world. Why leave money on the table? Every
                                advantage helps, especially when you’re starting
                                out.
                              </p>

                              <ul className="px-3">
                                <li style={{ listStyleType: "disc" }}>
                                  Disability
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Malpractice
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Health
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Auto & Home
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Income protection
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Reach the next level.
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                PracticeWisedr.com isn’t just about helping your
                                practice get off the ground. It’s a strategic
                                application of best practices that positions you
                                to reach new heights.
                              </p>

                              <p>
                                This is your future taking shape: Our
                                partnerships will give you the ability to focus
                                on patients and put more hours directly into
                                your practice. With the Accelerated Debt
                                Reduction program and by working with a seasoned
                                CPA firm, you will build up a great credit score
                                and have tight, professional financials. Before
                                too long, you have become an ideal client for
                                bank financing to help take your practice to the
                                next level.{" "}
                              </p>

                              <ul className="px-3">
                                <li style={{ listStyleType: "disc" }}>
                                  Refinance student loan debt for lower rates
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Finance a new practice
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Expand an existing practice
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              Stand out from the crowd.
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                The marketing and public relations firms on our
                                platform are experienced in promoting
                                professional services like yours. They can help
                                clarify what sets you apart from your
                                competition and express it in a simple and
                                emotionally compelling way.
                              </p>
                              <p>
                                It’s how your name becomes a brand, the
                                collective impact of all that is seen, heard,
                                and experienced by patients. Professional
                                stewardship of your brand – through design,
                                messaging, and events – can create a lasting
                                connection between your practice, your patients
                                and the community.
                              </p>

                              <ul className="px-3">
                                <li style={{ listStyleType: "disc" }}>
                                  Advertising and marketing
                                </li>
                                <li style={{ listStyleType: "disc" }}>
                                  Website design and development
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              Preserve your wealth. Live happily ever after.
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                Our platform gives you access to certified
                                financial planners who share all you need to
                                know about saving for retirement. Your current
                                dream is to start your own practice. After you
                                realize that dream, there will be others. Be
                                ready. Have a plan. Know your financial
                                independence number (FIN). Our financial
                                planning experts will help you chart your
                                course.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      {/* <ul className="service-menu">
                        <li className="active">
                          <Link to="/service-detail">
                            <span>Engine Diagnostics</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-detail">
                            <span>Lube Oil and Filters</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-detail">
                            <span>Belts and Hoses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-detail">
                            <span>Air Conditioning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-detail">
                            <span>Brake Repair</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/service-detail">
                            <span>Tire and Wheel Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                    {/* <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div> */}
                  </aside>
                </div>
                <div className="col-lg-12">
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h3 className="title mb-15">
                        Be the best doctor you can. We’ll do the rest.
                      </h3>
                      <p>
                        There is no reason you can’t succeed with your medical
                        practice. You have the passion. We have the expertise.
                        It’s gathered here, in one place, ready to help you
                        navigate your business challenges and reach your
                        professional and personal goals. There’s no telling how
                        far you can go. So why not start now by joining our
                        network and fulfilling your core business needs? Give us
                        a call. Let our best practices contribute to your best
                        life.
                      </p>

                      <p>
                        You could try to dig the knowledge out of the ground
                        yourself. You could mimic other practices that may or
                        may not be what you need. You could rely on trial and
                        error. In each case, you’re risking mediocrity while
                        taking precious time away from helping people and
                        elevating your expertise in medicine.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Solution;
