import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ls from "localstorage-slim";
import axios from "axios";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";

import Footer from "../layout/footer";

import "../../css/join-now.css";
import JoinSection from "../elements/join-section";
import { cryptoEncrypt } from "../helperFunctions/DataTransit";
import "../../../src/css/otp-input.css";
import { removeVar } from "../helperFunctions/HandleVar";

const TwoFactorAuth = () => {
  const [otpCode, setOtpCode] = useState("");
  const [email, setEmail] = useState("");
  const [res2FA, setRes2FA] = useState({
    msg: "",
    qrCode: "",
  });

  const history = useHistory();

  if (ls.get("0a1r0t", { decrypt: true }) !== true) history.push("/");
  function initiate2FA(email) {
    let encData = cryptoEncrypt({ email });
    return axios.post(`${process.env.REACT_APP_BACKEND}/auth/initiate/2fa`, {
      data: encData,
    });
  }

  const qrhandler = async () => {
    let email = ls.get("e31@0", { decrypt: true });
    setEmail(email);
    try {
      let twoFAResponse = await initiate2FA(email);
      setRes2FA({
        msg: twoFAResponse.data.message,
        qrCode: twoFAResponse.data.qrcode,
      });
    } catch (error) {
      return error;
    }
  };

  const verifyOTP = async (email) => {
    let encData = cryptoEncrypt({
      email,
      otp: otpCode,
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/verify/login/2fa`,
        { data: encData }
      );

      toast.success("Verified.");
      removeVar();
      setTimeout(() => {
        window.location = process.env.REACT_APP_PORTAL_PWDDS_URL;
      }, 6000);
      // console.log("res.data", res.data);
    } catch (error) {
      setOtpCode("");
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    // =================================
    // let email = "";
    // ls.set("e31@0", email, { encrypt: true });
    // // ===============================
    qrhandler();
    toast.info("Payment Successful!");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="page-content bg-white">
        <JoinSection />

        <section className="">
          {/* <div className="banner-img text-center my-3">
            <img src={onBoarding2} alt="" />
          </div> */}
          <div className="container bg-white shadow p-4 border rounded  custom-bg">
            <h6 className="text-uppercase my-3 text-center">
              Two-Factor Authentication
            </h6>
            <div className="row my-4 text-center">
              <div className="col-lg-12 ">
                {/* <h6>Set up two-factor authentication.</h6> */}
                <p>
                  To be able to login you need to scan this QR Code with
                  Authenticator Mobile App. Please{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                    target="_blank"
                  >
                    download
                  </a>{" "}
                  Authenticator Mobile App to progress.
                </p>
                <div>
                  <h5>{res2FA.msg}</h5>
                  <img src={res2FA.qrCode} />
                  {/* <div>
                    <a
                      href="https://portal-practicewisedr.netlify.app/"
                      className="btn btn-primary my-2"
                    >
                      Login
                    </a>
                  </div> */}

                  <div className="d-flex justify-content-center">
                    <div className="">
                      {/* <input
                        placeholder="Enter Code"
                        type="text"
                        className="form-control border p-2"
                        onChange={(e) => setOtpCode(e.target.value)}
                      /> */}
                      <OtpInput
                        value={otpCode}
                        onChange={(otp) => setOtpCode(otp)}
                        numInputs={6}
                        inputStyle="otp-input-style"
                        separator={<span>-</span>}
                      />
                      <button
                        type="button"
                        className="btn btn-primary my-4"
                        disabled={otpCode.length === 6 ? false : true}
                        onClick={() => otpCode.length === 6 && verifyOTP(email)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-6 my-4"></div> */}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default TwoFactorAuth;
