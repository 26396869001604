import React, { Component } from "react";

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements

import bnrImg1 from "../../images/banner/img1.jpg";
import circleDots from "../../images/shap/circle-dots.png";
import TeethRight from "../../images/shap/dr-logo.png";
import TeethLeft from "../../images/shap/dr-logo.png";

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Privacy Policy</h1>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={TeethLeft} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-wave" src={TeethRight} alt="" />
            </div>
          </div>
          <section className="section-sp1 about-area">
            <div className="container custom-res shadow-sm p-5 rounded">
              <h1>Privacy Policy </h1>
              <h6>Last Updated: July 28, 2022</h6>
              <p>
                Practice Wise Inc. and/or its affiliates (“us”, “we”, “our”, or
                “Practice Wise”) operates this website (the “Website”) and the
                Practice Wise mobile application (the “Mobile App”) that links
                to this Privacy Policy (collectively, the “Services”). This page
                informs you of our policies regarding the collection, use, and
                disclosure of personal information when you use our Services.
                This Privacy Policy does not address personal information that
                you provide to us in other contexts (e.g., through a business
                relationship not handled through the Website or the Mobile App).
              </p>

              <h6>Acceptance of Privacy Policy</h6>
              <p>
                By using the Services, you expressly consent to our collection,
                storage, use and disclosure of your personal information as
                described in this Privacy Policy. If you do not agree to the
                terms of this Privacy Policy, please do not use the Services.
                Your continued use of the Services following the posting of
                changes to the terms of this Privacy Policy will mean that you
                accept those changes.{" "}
              </p>

              <h6>How Do We Collect Your Personal Information?</h6>
              <p>
                Information You Give Us. We collect personally identifiable
                information such as your name, email address, phone number,
                postal address, demographic information such as gender or
                occupation, and other information you directly give us on the
                Website or Mobile App, including content contained in
                communications sent through the Services, or generated through
                your use of the Website e.g., through the use of cookies
                (“Personal Information”).
              </p>
              <p>
                Passive Information Collected. We may collect usage data and
                computer operating information, such as the operating system
                type, cookies, web beacons, browser type, browser language, the
                website you visited before browsing to our Website, pages you
                viewed, how long you spent on a page, access times, Internet
                protocol (“IP”) address, geolocation, and other information
                about your use of and actions on our Website or Mobile App to
                customize your visit through technical means.
              </p>

              <h6>How Do We Use Your Personal Information?</h6>

              <p>
                Our primary purpose in collecting Personal Information is to
                carry out and perform our contractual obligations with you and
                for our legitimate purpose of enhancing the Services.
              </p>

              <p>
                Any Personal Information collected by us in connection with your
                use of the Services will be used for the purposes specified in
                this Privacy Policy or as provided for on the applicable Website
                page or Mobile App. Any Personal Information submitted by you
                for publication on the Website or the Mobile App grants us a
                license to use your Personal Information. You may modify your
                privacy settings to limit our use of your information on the
                Services by adjusting the Website or the Mobile App privacy
                controls.
              </p>

              <p>
                Your Personal Information may be used by us for one or all of
                the following commercial reasons:
              </p>

              <ul className="px-5">
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Providing and improving the Services;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Identifying and communicating with you;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Internal record keeping;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Payment and transaction-based fraud-prevention to protect you,
                  your tenants, owners, service professionals you engage with
                  and other Website users;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Existing fraud prevention, which helps protect your listing
                  from others copying and stealing the listing;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Sending you promotional emails about new products, special
                  offers, or other information which we think you may find
                  interesting;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  {" "}
                  Customizing the Website and MobileApp according to your
                  interests.{" "}
                </li>
              </ul>

              <p>
                We have summarized below the lawful basis of processing your
                Personal Information:
              </p>

              <table className="table table-bordered mb-5">
                <thead>
                  <tr>
                    <th scope="col">Purpose</th>
                    <th scope="col">Lawful Basis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      To contact you and to respond to your requests, enquiries
                      and other communications
                    </td>
                    <td>
                      We have a legitimate interest to respond to your requests
                      and enquiries for ongoing business administration{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>To deliver Services to you</td>
                    <td>To manage and perform our contract with you</td>
                  </tr>
                  <tr>
                    <td>
                      For business administration, including statistical
                      analysis
                    </td>
                    <td>
                      We have a legitimate interest to properly manage and
                      administer our relationship with you and to ensure that we
                      are as effective and efficient as we can be
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To personalize your visit to the Website and to assist you
                      while you use the Website{" "}
                    </td>
                    <td>
                      We have a legitimate interest to properly manage and
                      administer our relationship with you and to ensure that we
                      are as effective and efficient as we can be
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To improve the Website by helping us understand who uses
                      the Website{" "}
                    </td>
                    <td>
                      We have a legitimate interest to properly manage and
                      administer our relationship with you and to ensure that we
                      are as effective and efficient as we can be
                    </td>
                  </tr>
                  <tr>
                    <td>
                      In connection with corporate sales or other restructuring
                      events
                    </td>
                    <td>
                      We have a legitimate interest to process your Personal
                      Information in connection with the sale or reorganization
                      of all or part of our business or its assets
                    </td>
                  </tr>
                  <tr>
                    <td>
                      For fraud prevention and detection and to comply with
                      applicable laws, regulations or codes of practice
                      including complying with any obligations that derive from
                      anti-money laundering and counter-terrorism legislation
                    </td>
                    <td>
                      To comply with our legal obligations and our legitimate
                      interests
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                You have a right to object to processing of your personal
                information where that processing is carried out for our
                legitimate interest.
              </p>

              <h6>How Do We Disclose Your Personal Information?</h6>

              <p>
                Publicly Posted Personal Information. If you make any Personal
                Information publicly available on the Website or Mobile App,
                such as by posting a listing which contains Personal
                Information, anyone may see and use such information and we are
                not responsible for such use. Practice Wise reserves the right
                to share any Personal Information you post on the Website or
                Mobile App with third parties without your prior consent. To the
                extent the Website or Mobile App does so, the subsequent use and
                disclosure of such information is subject to the privacy
                policies and practices of those third parties.
              </p>

              <p>
                Health, Safety, and Legal Requests. We will disclose your
                Personal Information where required to do so by law or subpoena
                or if we believe that such action is necessary to pursue our
                legitimate interest in complying with the law and the reasonable
                requests of law enforcement or of protecting the security or
                integrity of our Services.
              </p>

              <p>
                Business Transfers. If Practice Wise is involved in a merger,
                acquisition, or asset sale, your Personal Information may be
                transferred as a business asset. We will require any such
                successor business entity to honor the terms of this Privacy
                Policy.
              </p>

              <p>
                Practice Wise may disclose your Personal Information in the good
                faith belief that such action is necessary to:
              </p>
              <ul className="px-5">
                <li style={{ listStyleType: "disc" }}>
                  To comply with a legal obligation;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  To protect and defend the rights or property of Practice Wise;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  To prevent or investigate possible wrongdoing in connection
                  with the Services or the Website;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  To protect the personal safety or rights of users of the
                  Services or the public;{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  To protect against legal liability. Subject to applicable law,
                  we may share your Personal Information with:{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Partners and other third-party service providers: to operate
                  the Practice Wise’s Services, we may share your Personal
                  Information with our partners and other service providers.
                  These service providers may provide us with support services
                  such as origination of communications, receipt or support
                  services or customer relationship management services.{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Practice Wise group entities: we may share your Personal
                  Information with our affiliated entities, each of whom shall
                  comply with the terms of this Privacy Policy with respect to
                  its use and disclosure of such Personal Information.{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Third-parties through their Websites: the Website may enable
                  you to share information directly on or through websites or
                  online services operated by third parties. For example, the
                  Website may contain links to third-party websites. If you
                  choose to browse such websites, you may disclose your Personal
                  Information to those third-party websites. Practice Wise is
                  not liable for the content or action of such third-party
                  websites.{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  For legal purposes: we may disclose your Personal Information
                  for Practice Wise’s legitimate business interests or to comply
                  with applicable law, investigate, prevent, or take action
                  regarding illegal activities, suspected fraud, violations of
                  our terms and conditions, or to cooperate with regulatory
                  authorities or law enforcement, to establish, exercise or
                  defend our legal rights or defend against legal claims.{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Aggregated information. From time to time, Practice Wise may
                  also share anonymized and aggregated information about its
                  customers or users of the Website.{" "}
                </li>
              </ul>

              <h6>Cookies</h6>
              <p>
                When you use and access the Website or Mobile App, we may place
                a number of cookie files in your web browser. Both session and
                persistent cookies are used to run the Website.
              </p>

              <p>
                Cookies are files with small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Tracking technologies
                also used are beacons, tags, and scripts to collect and track
                information and to improve and analyze our Services. We may use
                cookies to: (1) allow you to use the Website without having to
                re-enter your user name and password; (2) enhance or personalize
                your Website usage and general experience; (3) monitor Website
                usage; (4) manage the Website; and (5) improve the Website and
                our Services [including providing you with interest-based ads].
              </p>

              <p>We use cookies for the following purposes:</p>

              <ul className="px-5">
                <li style={{ listStyleType: "disc" }}>
                  to enable certain functions of the Website;
                </li>
                <li style={{ listStyleType: "disc" }}>
                  to provide analytics and understand how you use our Website;
                </li>
                <li style={{ listStyleType: "disc" }}>
                  to store your preferences;
                </li>
                <li style={{ listStyleType: "disc" }}>
                  to enable advertisements delivery.
                </li>
              </ul>
              <p>
                You do not have to consent but not consenting may affect your
                experience using the Website. Unless consent to non-essential
                cookies is required under the applicable data privacy laws,
                cookies will be placed when you visit the Website. If you
                choose, you can set your browser to reject cookies or you can
                manually delete individual cookies or all of the cookies on your
                computer by following your browser’s help file directions. If
                you'd like to delete cookies or instruct your web browser to
                delete or refuse cookies, please visit the settings page of your
                web browser. Please note, however, that if you delete cookies or
                refuse to accept them, you might not be able to use all of the
                features we offer, you may not be able to store your
                preferences, and some of our pages might not display properly.
                However, if your browser is set to reject cookies or you
                manually delete cookies, you may have some trouble accessing and
                using some of the pages and features that are currently on our
                Website, or that we may put on our Website in the future. Note
                that browser-management tools for cookies are outside of our
                control and we cannot guarantee their effectiveness.
              </p>

              <p>
                We may combine the information we collect through cookies, web
                beacons, or other technology tools with other information we
                have collected from you or information from other sources. We
                use cookies and similar tracking technologies to track the
                activity on our Services and hold certain information.
              </p>

              <p>
                Unless otherwise required by law, these cookies will be placed
                when you visit the you can instruct your browser to refuse all
                cookies or to indicate when a cookie is being sent. However, if
                you do not accept cookies, you may not be able to use some
                portions of our Services.
              </p>

              <p>Examples of the types of cookies we use:</p>

              <ul className="px-5">
                <li style={{ listStyleType: "disc" }}>
                  Session Cookies. We use Session Cookies to operate our
                  Service.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Preference Cookies. We use Preference Cookies to remember your
                  preferences and various settings.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Security Cookies. We use Security Cookies for security
                  purposes.
                </li>
              </ul>

              <p>
                We have summarized below details of the cookies we use and for
                what purposes (also see below for details of our Analytics
                activities):
              </p>
              <p>
                In addition to our own cookies, we may also use various third
                party cookies to deliver advertisements for our service across
                the internet. The advertising techniques we use do not collect
                any information that personally identifies a user.
              </p>
              <p>
                We may use third-party advertising companies that use tracking
                technologies to serve our advertisements across the Internet.
                These companies may collect information about your visits to the
                Website and other websites and your interaction with our
                advertising and other communications. These advertising
                companies serve ads on behalf of us and others on non-affiliated
                sites, and some of those ads may be personalized, meaning that
                they are intended to be relevant to you based on information
                collected about your visits to the Website and elsewhere over
                time. Other companies may also use such technology to advertise
                on our Website.
              </p>

              <p>
                You have the choice to tell us not to collect and use this
                information, and in some jurisdictions, we will only engage in
                interest-based advertising if you opt-in. If you would like more
                information about this practice and to know your choices
                concerning interest-based ads, visit:
              </p>

              <p>http://www.networkadvertising.org/choices/</p>

              <p>www.aboutads.info/choices</p>

              <h6>Your California Rights</h6>

              <p>
                If you are a California resident, California law provides you
                with the following rights with respect to your Personal
                Information:
              </p>

              <ul className="px-5">
                <li style={{ listStyleType: "disc" }}>
                  The right to know what Personal Information we have collected,
                  used, disclosed and sold about you. To submit a request to
                  know, you may call us at 1-917-301-2522 or via
                  support@practicewisedr.com. You also may designate an
                  authorized agent to make a request for access on your behalf.{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The right to request that we delete any Personal Information
                  we have collected about you. To submit a request for deletion,
                  you may call us at 1-917-301-2522 or
                  support@practicewisedr.com. You also may designate an
                  authorized agent to make a request for deletion on your
                  behalf.
                </li>
              </ul>

              <p>
                When you exercise these rights and submit a request to us, we
                will verify your identity by asking you to log in to your
                account if you have one with us. If you do not have an account
                with us, we may ask for your email address, order numbers of
                previous orders of our products and services, the last four
                digits of a credit or debit card or a bank account number used
                to make a purchase, or the date of your last purchase from us.
                We also may use a third party verification provider to verify
                your identity.
              </p>

              <p>We do not sell your Personal Information.</p>

              <p>
                Your exercise of these rights will have no adverse effect on the
                price and quality of our goods or services.{" "}
              </p>

              <h6>Transfer Of Data</h6>
              <p>
                Your information, including Personal Information, may be
                transferred to the United States and other locations outside
                Europe and the United States where the data protection laws may
                differ, and be less stringent, than those from your
                jurisdiction.
              </p>

              <p>
                If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Information, to United States and process it
                there.
              </p>

              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>

              <h6>Retention of Personal Information</h6>

              <p>
                Personal Information that we collect, access or process will be
                retained only as long as necessary for the fulfilment of the
                purposes for which it was collected and for seven years after
                the later of the last commercial transaction or commercial
                communication with you, unless otherwise provided in agreements
                between you and Practice Wise or as required or authorized by
                law. Personal Information that is no longer required to fulfil
                the identified purposes and for our retention purposes will be
                destroyed, erased or de-identified.
              </p>

              <h6>Links to Third Party Websites</h6>
              <p>
                The Website and Mobile App may contain links to other websites
                that are not operated by us and may collect Personal Information
                about you. If you click on a third party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy of every site you visit. While we
                encourage our business partners to adopt privacy policies and
                practices that build users trust and confidence in the Internet,
                we are not responsible for the privacy policies or practices of
                third parties or any of the content of web sites linked to the
                Services or any third party products or services offered through
                the Services.
              </p>

              <h6>Children's Privacy Protection</h6>

              <p>Under Age 13</p>
              <p>Under Age 18</p>

              <p>
                Minors under 18 years of age may have the Personal Information
                that they have provided to Practice Wise through Services,
                including the Website or the Mobile App, deleted by sending an
                email to support@practicewisedr.com requesting deletion. Please
                note that, while we make reasonable efforts to comply with such
                requests, deletion of your Personal Information does not ensure
                complete and comprehensive removal of that data from all
                systems.
              </p>

              <h6>Response to “Do Not Track” Signals</h6>

              <p>
                Some Internet browsers include the ability to transmit “Do Not
                Track” signals. Because uniform standards for “Do Not Track”
                signals have not yet been adopted, Practice Wise does not
                process or respond to “Do Not Track” signals.
              </p>

              <h6>Security Measures</h6>

              <p>
                The security of your Personal Information is important to
                Practice Wise, and we strive to implement appropriate technical
                and organizational measures to protect your Personal Information
                against unauthorized access and disclosures.
              </p>
              <p>
                However, third parties may unlawfully intercept or access
                transmissions or private communications, and other users may
                abuse or misuse your Personal Information that they collect from
                the Website or the Mobile App. Therefore, although we take great
                care to protect your privacy, the confidentiality of any
                communication or material transmitted to/from Practice Wise
                through the Website or the Mobile App, or via e-mail, cannot be
                guaranteed.
              </p>

              <h6>Updating Your Information</h6>

              <p>
                If you wish to stop receiving e-mails or other communications
                from us, or if you have submitted Personal Information through
                our Website or Mobile App and would like that information
                deleted from our records, please notify us at
                support@practicewisedr.com.
              </p>

              <h6>Changes to this Privacy Policy</h6>

              <p>
                Privacy Policy may be revised from time to time as we add new
                features and services, as laws change, and as industry privacy
                and security best practices evolve. We display an effective date
                on the policy in the upper right corner of this Privacy Policy
                so that it will be easier for you to know when there has been a
                change. If we make any change to this Privacy Policy regarding
                use or disclosure of Personal Information, we will provide
                advance notice on the Services. Small changes or changes that do
                not significantly affect individual privacy interests may be
                made at any time and without prior notice.
              </p>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default PrivacyPolicy;
