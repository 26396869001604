import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/form.scss";

const GoalQuestion = ({ goal, setGoal }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [goals, setGoals] = useState([]);
  // const [goal, setGoal] = useState([]);
  const [isChecked, setIsChecked] = useState(
    Array(goals ? goals.length : 0).fill(false)
  );
  const getGoals = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND}/goal-questions`
      );
      setGoals(res.data.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getGoals();
  }, []);

  const checkboxHandler = (idx) => {
    const newArr = [...isChecked];
    if (newArr[idx]) {
      newArr[idx] = false;
    } else {
      newArr[idx] = true;
    }
    setIsChecked(newArr);
    let obj;
    for (let index = 0; index < newArr.length; index++) {
      if (newArr[index] === true) {
        obj = { goalQuestionId: goals[index].id };
        setQuestionId(obj);
      }
    }
  };

  const setQuestionId = (objId) => {
    let goalsArr = [];
    goalsArr.push(objId);
    setGoal(goalsArr);
  };

  const selectAllHandler = (e) => {
    setSelectAll(e.target.checked);
    let all;

    all = goals.map((item) => {
      return {
        goalQuestionId: item.id,
      };
    });
    if (e.target.checked === true) {
      setGoal(all);
    } else if (e.target.checked === false) {
      setGoal([]);
      setIsChecked([]);
    }
  };

  return (
    <>
      <h6 className="text-uppercase my-3 text-center">
        The world needs more great physicians. We make it easier for you to be
        one of them.
      </h6>

      <div className="control-group">
        <div className="check-all">
          <label className="control control-checkbox">
            Select All Options
            <input type="checkbox" onChange={selectAllHandler} />
            <div className="control_indicator"></div>
          </label>
        </div>
        <div className="custom-wrapper">
          {goals.map((item, index) => (
            <div className="label-container" key={item.id}>
              <label className="control control-checkbox">
                {item.question}
                <input
                  type="checkbox"
                  checked={
                    selectAll ? "checked" : isChecked[index] ? "checked" : ""
                  }
                  onChange={() => {
                    checkboxHandler(index);
                  }}
                />
                <div className="control_indicator"></div>
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GoalQuestion;
